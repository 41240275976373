import * as React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  ListProps,
  NumberField,
  ReferenceField,
  TextField,
} from "react-admin";
import Pagination from "../Components/Pagination";
import { theme } from "../theme/theme";
import { USER_TITLE_FIELD } from "../user/UserTitle";

export const IntegrationList = (props: ListProps): React.ReactElement => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"Integrations"}
      perPage={50}
      pagination={<Pagination />}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <Datagrid rowClick="show" optimized>
        <ReferenceField label="User" source="user.id" reference="User">
          <TextField source={USER_TITLE_FIELD} />
        </ReferenceField>
        <TextField label="Software" source="software" />
        <BooleanField label="Reminders" source="isCustomEmailAddressVerified" />
        <NumberField label="Days to fetch" source="daysOverDueToFetch" />
        <BooleanField label="Is Active" source="isActive" />
        <DateField
          source="lastSyncDate"
          label="Last Sync Date"
          locales="de-DE"
          style={{
            textAlign: "right",
            verticalAlign: "middle",
            color: theme.palette.grey[500],
            fontSize: "0.75rem",
            fontWeight: "bold",
          }}
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
        <DateField
          source="createdAt"
          label="Created At"
          locales="de-DE"
          style={{
            textAlign: "right",
            verticalAlign: "middle",
            color: theme.palette.grey[500],
            fontSize: "0.75rem",
            fontWeight: "bold",
          }}
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
      </Datagrid>
    </List>
  );
};
