export const ROLES = [
  {
    name: "lightManager",
    displayName: "LightManager",
  },
  {
    name: "admin",
    displayName: "Admin",
  },
  {
    name: "manager",
    displayName: "Manager",
  },
  {
    name: "creditor",
    displayName: "Creditor",
  },
  {
    name: "debtor",
    displayName: "Debtor",
  },
];
