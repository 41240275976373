import AlternateEmail from "@material-ui/icons/AlternateEmail";
import MarkunreadMailboxOutlined from "@material-ui/icons/MarkunreadMailboxOutlined";
import Phone from "@material-ui/icons/Phone";
import Reply from "@material-ui/icons/Reply";
import Sms from "@material-ui/icons/Sms";
import WhatsApp from "@material-ui/icons/WhatsApp";
import React from "react";
import { useListContext } from "react-admin";
import { Activity } from "../api/activity/Activity";
import { EnumActivityActivityType } from "../api/activity/EnumActivityActivityType";
import { EnumActivityResult } from "../api/activity/EnumActivityResult";
import { theme } from "../theme/theme";

export const ActivitySummary = React.memo(() => {
  const { data, loading } = useListContext();
  const activities: Activity[] = React.useMemo(
    () => (data ? Object.values(data) : []) as Activity[],
    [data]
  );

  const activityCounts = React.useMemo(() => {
    const counts = {
      failedFullPhoneCalls: 0,
      successfulPhoneCalls: 0,
      outboundEmails: 0,
      outboundLetters: 0,
      plannedEmails: 0,
      plannedLetters: 0,
      neutralResponses: 0,
      positiveResponses: 0,
      outboundWhatsApps: 0,
      plannedWhatsApps: 0,
      outboundSmss: 0,
      plannedSmss: 0,
    };

    activities.forEach((activity) => {
      switch (activity.activityType) {
        case EnumActivityActivityType.OutboundCall:
          if (
            activity.result === EnumActivityResult.NotReached &&
            !activity.isPlanned
          ) {
            counts.failedFullPhoneCalls++;
          } else if (!activity.isPlanned) {
            counts.successfulPhoneCalls++;
          }
          break;
        case EnumActivityActivityType.OutboundMail:
          activity.isPlanned ? counts.plannedEmails++ : counts.outboundEmails++;
          break;
        case EnumActivityActivityType.OutboundLetter:
          activity.isPlanned
            ? counts.plannedLetters++
            : counts.outboundLetters++;
          break;
        case EnumActivityActivityType.Response:
          if (
            activity.result === EnumActivityResult.ReachedPromisedToPay ||
            activity.result === EnumActivityResult.ReachedPaymentPlanSetup
          ) {
            counts.positiveResponses++;
          } else {
            counts.neutralResponses++;
          }
          break;
        case EnumActivityActivityType.OutboundWhatsapp:
          activity.isPlanned
            ? counts.plannedWhatsApps++
            : counts.outboundWhatsApps++;
          break;
        case EnumActivityActivityType.OutboundSms:
          activity.isPlanned ? counts.plannedSmss++ : counts.outboundSmss++;
          break;
        default:
          break;
      }
    });

    return counts;
  }, [activities]);

  if (loading || !activities || !activities.length) {
    return null;
  }

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {(activityCounts.outboundEmails !== 0 ||
          activityCounts.plannedEmails !== 0) && (
          <span
            title="Outbound emails"
            style={{
              color: theme.palette.info.main,
              padding: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            {activityCounts.outboundEmails}
            <AlternateEmail
              style={{
                width: "auto",
                height: 14,
                marginRight: 2,
              }}
            />
          </span>
        )}
        {activityCounts.plannedEmails !== 0 && (
          <span
            title="Planned emails"
            style={{
              color: theme.palette.grey[500],
              padding: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            {activityCounts.plannedEmails}
            <AlternateEmail
              style={{
                width: "auto",
                height: 14,
                marginRight: 2,
              }}
            />
          </span>
        )}
        {(activityCounts.outboundLetters !== 0 ||
          activityCounts.plannedLetters !== 0) && (
          <span
            title="Outbound letters"
            style={{
              color: theme.palette.info.main,
              padding: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            {activityCounts.outboundLetters}
            <MarkunreadMailboxOutlined
              style={{
                width: "auto",
                height: 14,
                marginRight: 2,
              }}
            />
          </span>
        )}
        {activityCounts.plannedLetters !== 0 && (
          <span
            title="Planned letters"
            style={{
              color: theme.palette.grey[500],
              padding: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            {activityCounts.plannedLetters}
            <MarkunreadMailboxOutlined
              style={{
                width: "auto",
                height: 14,
                marginRight: 2,
              }}
            />
          </span>
        )}
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        {(activityCounts.outboundWhatsApps !== 0 ||
          activityCounts.plannedWhatsApps !== 0) && (
          <span
            title="Outbound WhatsApps"
            style={{
              color: theme.palette.info.main,
              padding: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            {activityCounts.outboundWhatsApps}
            <WhatsApp
              style={{
                width: "auto",
                height: 14,
                marginRight: 2,
              }}
            />
          </span>
        )}
        {activityCounts.plannedWhatsApps !== 0 && (
          <span
            title="Planned WhatsApps"
            style={{
              color: theme.palette.grey[500],
              padding: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            {activityCounts.plannedWhatsApps}
            <WhatsApp
              style={{
                width: "auto",
                height: 14,
                marginRight: 2,
              }}
            />
          </span>
        )}
        {(activityCounts.outboundSmss !== 0 ||
          activityCounts.plannedSmss !== 0) && (
          <span
            title="Outbound SMSs"
            style={{
              color: theme.palette.info.main,
              padding: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            {activityCounts.outboundSmss}
            <Sms
              style={{
                width: "auto",
                height: 14,
                marginRight: 2,
              }}
            />
          </span>
        )}
        {activityCounts.plannedSmss !== 0 && (
          <span
            title="Planned SMSs"
            style={{
              color: theme.palette.grey[500],
              padding: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            {activityCounts.plannedSmss}
            <Sms
              style={{
                width: "auto",
                height: 14,
                marginRight: 2,
              }}
            />
          </span>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {activityCounts.successfulPhoneCalls !== 0 && (
          <span
            title="Successful calls"
            style={{
              color: theme.palette.success.main,
              padding: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            {activityCounts.successfulPhoneCalls}
            <Phone
              style={{
                width: "auto",
                height: 14,
                marginRight: 2,
              }}
            />
          </span>
        )}
        {activityCounts.failedFullPhoneCalls !== 0 && (
          <span
            title="Failed calls"
            style={{
              color: theme.palette.error.main,
              padding: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            {activityCounts.failedFullPhoneCalls}
            <Phone
              style={{
                width: "auto",
                height: 14,
                marginRight: 2,
              }}
            />
          </span>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {activityCounts.positiveResponses !== 0 && (
          <span
            title="Positive responses"
            style={{
              color: theme.palette.success.main,
              padding: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            {activityCounts.positiveResponses}
            <Reply
              style={{
                width: "auto",
                height: 16,
                marginRight: 2,
              }}
            />
          </span>
        )}
        {activityCounts.neutralResponses !== 0 && (
          <span
            title="Neutral responses"
            style={{
              color: theme.palette.warning.main,
              padding: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            {activityCounts.neutralResponses}
            <Reply
              style={{
                width: "auto",
                height: 16,
                marginRight: 2,
              }}
            />
          </span>
        )}
      </div>
    </div>
  );
});
