import {
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";

import React, { useCallback, useEffect, useState } from "react";
import { Title, useGetIdentity } from "react-admin";

import EventNoteIcon from "@material-ui/icons/EventNote";
import ExploreIcon from "@material-ui/icons/Explore";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import GavelIcon from "@material-ui/icons/Gavel";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";

import { Timelapse } from "@material-ui/icons";
import { format } from "date-fns";
import { ClaimSummary } from "../Components/ClaimSummary";
import { getActivityTypeIcon } from "../Components/icon/ActivityTypeIcon";
import ClaimsToDoTab from "../Components/tabs/dashboard/ClaimsToDoTab";
import InsolvencyActivityToDoTab from "../Components/tabs/dashboard/InsolvencyActivityToDoTab";
import JudicialActivityToDoTab from "../Components/tabs/dashboard/JudicialActivityToDoTab";
import OtherToDoTab from "../Components/tabs/dashboard/OtherToDoTab";
import PlannedCallsToDoTab from "../Components/tabs/dashboard/PlannedCallsToDoTab";
import ResearchToDoTab from "../Components/tabs/dashboard/ResearchToDoTab";
import WaitingForCreditorToDoTab from "../Components/tabs/dashboard/WaitingForCreditorToDoTab";
import { Activity, ActivityTypes } from "../api/activity/Activity";
import { Claim } from "../api/claim/Claim";
import { theme } from "../theme/theme";

const today: Date = new Date();
today.setHours(23, 59, 59, 59);

const Dashboard = React.memo(() => {
  const [callsLoaded, setCallsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAssignedClaims, setIsLoadingAssignedClaims] = useState(false);
  const [personalization, setPersonalization] = useState(true);
  const [allowLegal, setAllowLegal] = useState(false);
  const [enableLegal, setEnableLegal] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [parentTabValue, setParentTabValue] = React.useState(0);
  const [totalPlannedCalls, setTotalPlannedCalls] = useState(0);
  const [totalWaiting, setTotalWaiting] = useState("0");
  const [totalOther, setTotalOther] = useState(0);
  const [totalResearch, setTotalResearch] = useState(0);
  const [totalJudicialActivity, setTotalJudicialActivity] = useState(0);
  const [totalInsolvencyActivity, setTotalInsolvencyActivity] = useState(0);
  const [totalWaitingForCreditor, setTotalWaitingForCreditor] = useState(0);
  const [loadedAssignedClaims, setLoadedAssignedClaims] = useState([]);

  const handleChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
    },
    [setTabValue]
  );

  const handleParentTabChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setParentTabValue(newValue);
    },
    [setParentTabValue]
  );

  const { identity } = useGetIdentity();

  const fetchAssignedClaims = useCallback(async () => {
    if (
      !identity?.id ||
      isLoadingAssignedClaims ||
      loadedAssignedClaims?.length
    ) {
      return;
    }

    setIsLoadingAssignedClaims(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/users/${identity.id}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("credentials") || "",
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch user: ${response.status}`);
      }

      const userData = await response.json();
      if (!userData.claimsAsAssignee) {
        setIsLoadingAssignedClaims(false);
      }
      if (userData.hasLegalKnowledge) {
        setAllowLegal(true);
      }
      setLoadedAssignedClaims(userData.claimsAsAssignee || []);
    } catch (error) {
      console.error("Error fetching assigned claims:", error);
    } finally {
      setIsLoadingAssignedClaims(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identity?.id]);

  useEffect(() => {
    if (loadedAssignedClaims.length === 0 && !isLoading) {
      fetchAssignedClaims();
    }
  }, [fetchAssignedClaims, loadedAssignedClaims.length, isLoading]);

  return (
    <div>
      <Title title="Dashboard" />
      <Grid container spacing={2} alignItems="center">
        <Grid xs={12} md={6} item>
          <Typography variant="h5">
            <Tabs
              onChange={handleParentTabChange}
              value={parentTabValue}
              aria-label={"dashboard main tabs"}
              variant={"scrollable"}
              scrollButtons={"auto"}
              style={{
                marginBottom: "2rem",
              }}
            >
              <Tab
                label={`${
                  identity?.fullName?.includes(" ")
                    ? identity?.fullName?.split(" ")[0] + "'s "
                    : identity?.fullName + "'s "
                } Dashboard`}
              />
              <Tab
                label={"Meine Akten"}
                disabled={loadedAssignedClaims.length < 1}
              />
            </Tabs>
          </Typography>
        </Grid>
        <Grid xs={12} md={6} item style={{ textAlign: "right" }}>
          {allowLegal && (
            <FormControlLabel
              label="Legal Mode"
              value={enableLegal}
              onChange={(_e, checked) => {
                setEnableLegal(checked);
              }}
              disabled={isLoading}
              control={
                <Switch color="secondary" defaultChecked={enableLegal} />
              }
              labelPlacement={"start"}
            />
          )}
          <FormControlLabel
            label="Personalized"
            value={personalization}
            onChange={(_e, checked) => {
              setPersonalization(checked);
            }}
            disabled={isLoading}
            control={
              <Switch color="secondary" defaultChecked={personalization} />
            }
            labelPlacement={"start"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems={"flex-start"}>
        <Grid xs={12} md={3} item>
          <Card variant="outlined">
            <CardContent className="flex-center" style={{ minHeight: 20 }}>
              <Typography
                variant="subtitle2"
                style={{
                  fontWeight: "bold",
                }}
              >
                {loadedAssignedClaims.length === 0
                  ? "Keine zugewiesenen Akten"
                  : `Zugewiesene Akten: ${loadedAssignedClaims.length}`}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid md={6} item className="mobile-hide"></Grid>
        <Grid xs={12} md={3} item>
          <Automations />
        </Grid>
      </Grid>
      <div
        role="tabpanel"
        hidden={parentTabValue !== 0}
        id={`parent-tabpanel-${0}`}
      >
        <Tabs
          onChange={handleChange}
          value={tabValue}
          aria-label={"dashboard tabs"}
          style={{
            marginTop: 25,
            marginBottom: 15,
            background: "rgba(255, 255, 255, 0.5)",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "rgba(0, 0, 0, 0.12)",
            borderRadius: 8,
            maxWidth: "100%",
          }}
          variant={"scrollable"}
          scrollButtons={"auto"}
        >
          <Tab
            label={
              "Calls" +
              (callsLoaded === true && tabValue === 0 && totalPlannedCalls > 0
                ? ` (${totalPlannedCalls})`
                : "")
            }
            icon={<EventNoteIcon />}
          />
          <Tab
            label={
              "Waiting" +
              (tabValue === 1 && Number(totalWaiting.replaceAll("+", "")) > 0
                ? ` (${totalWaiting})`
                : "")
            }
            icon={<FormatListBulletedIcon />}
          />
          <Tab
            label={
              `Other` +
              (tabValue === 2 && totalOther > 0 ? ` (${totalOther})` : "")
            }
            icon={<HelpOutlinedIcon />}
          />
          <Tab
            label={
              `Research` +
              (tabValue === 3 && totalResearch > 0 ? ` (${totalResearch})` : "")
            }
            icon={<ExploreIcon />}
          />
          <Tab
            label={
              `Judicial activity` +
              (tabValue === 4 && totalJudicialActivity > 0
                ? ` (${totalJudicialActivity})`
                : "")
            }
            icon={<GavelIcon />}
          />
          <Tab
            label={
              `Insolvency activity` +
              (tabValue === 5 && totalInsolvencyActivity > 0
                ? ` (${totalInsolvencyActivity})`
                : "")
            }
            icon={<MoneyOffIcon />}
          />
          <Tab
            label={
              `Waiting for Creditor` +
              (tabValue === 6 && totalWaitingForCreditor > 0
                ? ` (${totalWaitingForCreditor})`
                : "")
            }
            icon={<Timelapse />}
          />
        </Tabs>

        <PlannedCallsToDoTab
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          personalization={personalization}
          enableLegal={enableLegal}
          setCallsLoaded={setCallsLoaded}
          setTotalPlannedCalls={setTotalPlannedCalls}
          tabValue={tabValue}
        />

        <ClaimsToDoTab
          personalization={personalization}
          enableLegal={enableLegal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setTotalWaiting={setTotalWaiting}
          tabValue={tabValue}
        />

        <OtherToDoTab
          personalization={personalization}
          enableLegal={enableLegal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setTotalOther={setTotalOther}
          tabValue={tabValue}
        />

        <ResearchToDoTab
          personalization={personalization}
          enableLegal={enableLegal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setTotalResearch={setTotalResearch}
          tabValue={tabValue}
        />

        {allowLegal && (
          <JudicialActivityToDoTab
            personalization={personalization}
            enableLegal={enableLegal}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setTotalJudicialActivity={setTotalJudicialActivity}
            tabValue={tabValue}
          />
        )}

        <InsolvencyActivityToDoTab
          personalization={personalization}
          enableLegal={enableLegal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setTotalInsolvencyActivity={setTotalInsolvencyActivity}
          tabValue={tabValue}
        />

        <WaitingForCreditorToDoTab
          personalization={personalization}
          enableLegal={enableLegal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setTotalWaitingForCreditor={setTotalWaitingForCreditor}
          tabValue={tabValue}
        />
      </div>
      <div
        role="tabpanel"
        hidden={parentTabValue !== 1}
        id={`parent-tabpanel-${1}`}
        style={{ marginTop: 20 }}
      >
        {loadedAssignedClaims && loadedAssignedClaims.length > 0
          ? loadedAssignedClaims.map((claim: Claim) => {
              return (
                <ClaimSummary
                  claim={claim}
                  type={"claim"}
                  key={claim.id}
                  refresh={() => {
                    fetchAssignedClaims();
                  }}
                />
              );
            })
          : "-"}
      </div>
    </div>
  );
});

const Automations = React.memo(() => {
  const [overdueAutomations, setOverdueAutomations] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const authHeader = {
      headers: {
        Authorization: localStorage.getItem("credentials") || "",
        "Content-Type": "application/json",
      },
    };

    fetch(process.env.REACT_APP_SERVER_URL + "/api/activities/cron/monitor", {
      ...authHeader,
    })
      .then((response) => response.json())
      .then((parsedResponse) => {
        if (parsedResponse.length) {
          setOverdueAutomations(parsedResponse);
        }
      });
  }, []);

  return (
    <>
      <Card
        variant="outlined"
        style={{
          background:
            overdueAutomations && overdueAutomations.length > 0
              ? overdueAutomations.length > 100
                ? theme.palette.error.main
                : theme.palette.warning.main
              : theme.palette.success.main,
        }}
      >
        <CardContent
          className="flex-center"
          style={{ minHeight: 20, padding: 5 }}
        >
          <Typography variant="subtitle2">
            <a
              onClick={(e) => {
                e.preventDefault();
                setShowDetails(!showDetails);
              }}
              href={`/#/Activity?displayedFilters=%7B%7D&filter=%7B"isPlanned"%3Atrue%2C"activityDate"%3A"${
                today.toISOString().split("T")[0]
              }"%7D`}
              style={{
                color: "white",
                fontWeight: "bold",
                textDecoration: "none",
              }}
            >
              Wartende Automations:{" "}
              <span style={{ fontWeight: "bold" }}>
                {overdueAutomations?.length && overdueAutomations?.length > 100
                  ? "🔥 "
                  : ""}
                {overdueAutomations?.length ? overdueAutomations.length : "0"}
                {overdueAutomations?.length && overdueAutomations?.length > 100
                  ? " 🔥"
                  : ""}
              </span>
            </a>
          </Typography>
        </CardContent>
      </Card>
      {showDetails && (
        <Card>
          <List
            style={{
              maxHeight: 300,
              overflow: "hidden",
              overflowY: "auto",
            }}
          >
            {overdueAutomations &&
              overdueAutomations.map((activity: Activity, key) => {
                return (
                  <a
                    href={"#/Activity/" + activity.id + "/show"}
                    style={{
                      textDecoration: "none",
                      color: theme.palette.text.primary,
                    }}
                  >
                    <ListItem
                      key={activity.id}
                      id={activity.id}
                      className="list-item-activity"
                    >
                      {getActivityTypeIcon(
                        activity.activityType as ActivityTypes
                      )}
                      <div className="col">
                        <span className="date">{`${format(
                          activity.activityDate,
                          "dd.MM.yyyy"
                        )}: `}</span>

                        <span className="reference">
                          {activity.claim?.reference}
                        </span>
                        <span className="company">
                          {activity.claim?.creditor?.businessName}
                        </span>
                      </div>
                    </ListItem>
                    {overdueAutomations.length - 1 !== key && <Divider />}
                  </a>
                );
              })}
          </List>
        </Card>
      )}
    </>
  );
});

export default Dashboard;
