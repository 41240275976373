import { Slider, withStyles } from "@material-ui/core";

type CustomSliderProps = {
  color?: string;
  classes?: any;
  defaultValue?: number;
  value?: number;
  step?: number;
  valueLabelDisplay?: string;
};

export const CustomSlider = withStyles({
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: (props: CustomSliderProps) => props.color ?? "#fff",
    border: "1px solid currentColor",
    marginTop: -9,
    marginLeft: -8,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    color: (props: CustomSliderProps) => props.color ?? "#fff",
  },
  rail: {
    height: 4,
    borderRadius: 20,
  },
  track: {
    height: 4,
    borderRadius: 20,
  },
  valueLabel: {
    color: "#ddd",
    marginLeft: 6,
    boxShadow: "#ebebeb 0 4px 2px",
    background: (props: CustomSliderProps) => props.color ?? "#fff",
    borderRadius: 100,
  },
})(({ ...props }: any) => {
  return <Slider {...props} />;
});
