import { Address as TAddress } from "../api/address/Address";

export const ADDRESS_TITLE_FIELD = "addressLine1";

export const AddressTitle = (record: TAddress): string => {
  return (
    (record?.addressLine1 &&
      record?.town &&
      record?.addressLine1 +
        ", " +
        record?.postalcode +
        " " +
        record?.town +
        ", " +
        record?.country) ||
    String(record?.id) ||
    "(empty)"
  );
};
