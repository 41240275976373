import {
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import {
  DateField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { USER_TITLE_FIELD } from "../user/UserTitle";

export const PaymentInformationShow = (
  props: ShowProps
): React.ReactElement => {
  return (
    <div className="removeShowCard">
      <Show {...props}>
        <SimpleShowLayout>
          <Grid container style={{ width: "100%" }}>
            <Grid item xs={12} lg={6}>
              <Card className="keepCardStyle">
                <CardContent>
                  <List>
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            Account / IBAN:
                          </Typography>
                        }
                        secondary={
                          <TextField
                            label="Account"
                            source="account"
                            style={{ fontWeight: 600 }}
                          />
                        }
                      ></ListItemText>
                    </ListItem>
                    <Divider component="li" />
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            Bank Identifier / SWIFT (only for international):
                          </Typography>
                        }
                        secondary={
                          <TextField
                            label="Bank"
                            source="bankIdentifier"
                            style={{ fontWeight: 600 }}
                          />
                        }
                      ></ListItemText>
                    </ListItem>
                    <Divider component="li" />
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">Currency:</Typography>
                        }
                        secondary={
                          <TextField
                            label="Currency"
                            source="currency"
                            emptyText="(all)"
                          />
                        }
                      ></ListItemText>
                    </ListItem>
                    <Divider component="li" />
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">User:</Typography>
                        }
                        secondary={
                          <ReferenceField
                            link="show"
                            label="User"
                            source="user.id"
                            reference="User"
                          >
                            <TextField
                              source={USER_TITLE_FIELD}
                              style={{ fontWeight: 600 }}
                            />
                          </ReferenceField>
                        }
                      ></ListItemText>
                    </ListItem>
                    <Divider component="li" />
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">Method:</Typography>
                        }
                        secondary={
                          <TextField
                            label="Method"
                            source="method"
                            style={{ fontWeight: 600 }}
                          />
                        }
                      ></ListItemText>
                    </ListItem>
                    <Divider component="li" />
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={<Typography variant="caption">ID:</Typography>}
                        secondary={
                          <TextField
                            label="ID"
                            source="id"
                            style={{ fontWeight: 600 }}
                          />
                        }
                      ></ListItemText>
                    </ListItem>
                    <Divider component="li" />
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">Created at:</Typography>
                        }
                        secondary={
                          <DateField
                            source="createdAt"
                            label="Created At"
                            locales="de-DE"
                            options={{
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                              timeZone: "Europe/Berlin",
                            }}
                            style={{ fontWeight: 600 }}
                          />
                        }
                      ></ListItemText>
                    </ListItem>
                    <Divider component="li" />
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">Updated at:</Typography>
                        }
                        secondary={
                          <DateField
                            source="updatedAt"
                            label="Updated At"
                            locales="de-DE"
                            options={{
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                              timeZone: "Europe/Berlin",
                            }}
                            style={{ fontWeight: 600 }}
                          />
                        }
                      ></ListItemText>
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </SimpleShowLayout>
      </Show>
    </div>
  );
};
