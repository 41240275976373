import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardActionArea,
  Chip,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import {
  AlternateEmail,
  AttachFile,
  CheckCircleRounded,
  KeyboardArrowDown,
  Mail,
  Phone,
  RemoveCircleRounded,
  Sms,
  SwapVerticalCircleRounded,
  WhatsApp,
} from "@material-ui/icons";
import React from "react";
import {
  DateField,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import {
  Activity,
  generateTeaser,
  getReadableActivityType,
  getReadableResult,
  getReadableSubjectByClaimAction,
} from "../api/activity/Activity";
import { theme } from "../theme/theme";
import UserPresentation from "./UserPresentation";

const ActivityTypePresentation = () => {
  const record = useRecordContext();
  if (record?.activityType?.startsWith("Outbound")) {
    return (
      <span
        title="Planned letters"
        style={{
          padding: 2,
          color: theme.palette.secondary.main,
        }}
      >
        {record?.activityType === "OutboundLetter" && (
          <Mail
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 3,
              marginBottom: 2,
            }}
          />
        )}
        {record?.activityType === "OutboundMail" && (
          <AlternateEmail
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 3,
              marginBottom: 2,
            }}
          />
        )}
        {record?.activityType === "OutboundCall" && (
          <Phone
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 3,
              marginBottom: 2,
            }}
          />
        )}
        {record?.activityType === "OutboundSms" && (
          <Sms
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 3,
              marginBottom: 2,
            }}
          />
        )}
        {record?.activityType === "OutboundWhatsapp" && (
          <WhatsApp
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 3,
              marginBottom: 2,
            }}
          />
        )}
        {!record?.claimAction && (
          <span style={{ fontWeight: "bold" }}>
            {getReadableActivityType(record?.activityType)}{" "}
          </span>
        )}
      </span>
    );
  } else {
    return (
      <span
        style={{
          fontWeight: "bold",
          color:
            record?.activityType === "Response"
              ? theme.palette.error.dark
              : record?.activityType === "CreditorFeedback" ||
                record?.activityType === "CreditorDecision"
              ? theme.palette.success.dark
              : theme.palette.grey[800],
        }}
      >
        {getReadableActivityType(record?.activityType)}{" "}
      </span>
    );
  }
};

const ActivityClaimActionPresentation = () => {
  const record: Activity = useRecordContext();
  if (!!record?.claimAction) {
    return (
      <>
        <b style={{ color: theme.palette.secondary.main }}>
          {getReadableSubjectByClaimAction(record?.claimAction)}
        </b>
      </>
    );
  }

  return null;
};

const ActivityResultPresentation = () => {
  const record: Activity = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  if (!!record?.result) {
    return (
      <div style={{ marginTop: 2 }}>
        <Chip
          size="small"
          label={getReadableResult(record?.result)}
          style={{
            fontSize: 11,
            color: "#fff",
            fontWeight: "bold",
            background:
              record?.result === "NotReached" || record?.result === "Negative"
                ? theme.palette.error.dark
                : theme.palette.success.dark,
          }}
        />
      </div>
    );
  }

  if (record?.activityType?.startsWith("Recommended") && record?.isPlanned) {
    return (
      <div style={{ marginTop: 4 }}>
        <CardActionArea
          style={{
            display: "inline",
            padding: 5,
            width: "auto",
            textAlign: "center",
            borderRadius: 10,
            marginRight: 5,
          }}
          onClick={async (e) => {
            e.stopPropagation();
            if (window.confirm("Confirm approval?")) {
              try {
                const result = await dataProvider.create("Activity", {
                  data: {
                    activityType:
                      record?.activityType === "RecommendedOutboundMail"
                        ? "OutboundMail"
                        : "InternalFeedback",
                    claim: {
                      id: record?.claim?.id,
                    },
                    isPlanned: false,
                    activityDate: new Date(),
                    comment: record?.comment,
                    fileUrls: record?.fileUrls,
                  },
                });
                if (result?.data?.id) {
                  await dataProvider.update("Activity", {
                    id: record?.id,
                    data: { isPlanned: false, result: "Positive" },
                    previousData: record,
                  });
                }
              } catch (e) {
                notify(
                  "Error during automatic handling. Please perform the action manually.",
                  "error",
                  null,
                  false
                );
              }
              refresh();
            }
          }}
        >
          <Chip
            size="medium"
            label={
              <>
                <CheckCircleRounded
                  fontSize={"small"}
                  style={{
                    width: 12,
                    height: 12,
                    color: "#fff",
                    top: 2,
                    left: -4,
                    position: "relative",
                  }}
                />
                {"Senden"}
              </>
            }
            style={{
              fontSize: 11,
              padding: 3,
              paddingBottom: 6,
              paddingTop: 6,
              color: "#fff",
              fontWeight: "bold",
              background: theme.palette.success.main,
              cursor: "pointer",
            }}
          />
        </CardActionArea>
        <CardActionArea
          style={{
            display: "inline",
            padding: 5,
            width: "auto",
            textAlign: "center",
            borderRadius: 10,
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (window.confirm("Confirm deletion?")) {
              dataProvider.update("Activity", {
                id: record?.id,
                data: { isPlanned: false, result: "Negative" },
                previousData: record,
              });
            }
          }}
        >
          <Chip
            size="medium"
            label={
              <>
                <RemoveCircleRounded
                  fontSize={"small"}
                  style={{
                    width: 12,
                    height: 12,
                    color: "#fff",
                    top: 2,
                    left: -4,
                    position: "relative",
                  }}
                />
                {"Verwerfen"}
              </>
            }
            style={{
              fontSize: 11,
              padding: 3,
              paddingBottom: 6,
              paddingTop: 6,
              color: "#fff",
              fontWeight: "bold",
              background: theme.palette.error.main,
              cursor: "pointer",
            }}
          />
        </CardActionArea>
        <CardActionArea
          style={{
            display: "block",
            padding: 5,
            width: "auto",
            textAlign: "center",
            borderRadius: 10,
          }}
        >
          <Chip
            size="medium"
            label={
              <>
                <SwapVerticalCircleRounded
                  fontSize={"small"}
                  style={{
                    width: 12,
                    height: 12,
                    color: "#fff",
                    top: 2,
                    left: -4,
                    position: "relative",
                  }}
                />
                {"Bearbeiten"}
              </>
            }
            style={{
              fontSize: 11,
              padding: 3,
              paddingBottom: 6,
              paddingTop: 6,
              color: "#fff",
              fontWeight: "bold",
              background: theme.palette.info.main,
              cursor: "pointer",
            }}
          />
        </CardActionArea>
      </div>
    );
  }

  return null;
};

export const ActivityComment = (props: any) => {
  const record: Activity = useRecordContext();
  const [showDetails, setShowDetails] = React.useState<boolean>(false);

  if (!record?.comment) {
    return null;
  }
  const { teaser, fullComment } = generateTeaser(record.comment);

  if (fullComment !== teaser && fullComment) {
    return (
      <Accordion
        variant="outlined"
        square
        style={{
          border: 0,
          background: "transparent",
          padding: 0,
          margin: 0,
        }}
        expanded={showDetails}
        onClick={(e) => {
          if (!showDetails) {
            e.stopPropagation();
          }
        }}
        onChange={(e) => {
          setShowDetails(!showDetails);
        }}
      >
        <AccordionSummary
          style={{
            padding: 0,
            margin: 0,
            userSelect: "text",
            wordBreak: "break-word",
            minWidth: 350,
            width: "100%",
            display: "inline-block",
            whiteSpace: "pre-line",
          }}
          onClick={(e) => {
            if (!showDetails) {
              e.stopPropagation();
            }
          }}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography variant="body2">
            {teaser}
            <br />
            {!showDetails && (
              <>
                <Typography variant="button" color="secondary">
                  Mehr anzeigen
                </Typography>
                <KeyboardArrowDown
                  style={{
                    float: "left",
                    color: theme.palette.secondary.main,
                  }}
                />
                {renderFileIndicator(record)}
              </>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            wordBreak: "break-word",
            minWidth: 350,
            width: "100%",
            display: "inline-block",
            whiteSpace: "pre-line",
            padding: 0,
          }}
        >
          {fullComment}
          {renderFileIndicator(record)}
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <div
      style={{
        wordBreak: "break-word",
        minWidth: 350,
        width: "100%",
        display: "inline-block",
        whiteSpace: "pre-line",
        position: "relative",
      }}
    >
      {record?.comment}
      {renderFileIndicator(record)}
    </div>
  );
};

const renderFileIndicator = (record: Activity) => {
  if (record && (record.fileUrl || record.fileUrls)) {
    const fileNames = [];
    if (record.fileUrl) {
      fileNames.push(record.fileUrl.split("/").pop());
    }
    if (record.fileUrls) {
      try {
        if (Array.isArray(record.fileUrls) && record.fileUrls.length) {
          for (const parsedFileUrl of record.fileUrls) {
            fileNames.push(parsedFileUrl.toString().split("/").pop());
          }
        } else {
          const parsedFileUrls = JSON.parse(record.fileUrls.toString());
          for (const parsedFileUrl of parsedFileUrls) {
            fileNames.push(parsedFileUrl.split("/").pop());
          }
        }
      } catch (e) {}
    }

    return (
      <div style={{ position: "absolute", right: 0, bottom: 0 }}>
        {fileNames.map((fileName: string, index: number) => {
          return (
            <Tooltip arrow title={fileName} key={fileName}>
              <span
                style={{
                  marginLeft: 2,
                  marginRight: 2,
                  padding: 3,
                  verticalAlign: "middle",
                }}
              >
                <Typography
                  style={{
                    display: "inline-block",
                    fontSize: 12,
                    color: theme.palette.grey[600],
                  }}
                  variant="subtitle2"
                >
                  {index + 1}
                </Typography>
                <AttachFile
                  style={{
                    color: theme.palette.grey[600],
                    height: 18,
                    width: 18,
                    marginBottom: -4,
                  }}
                />
              </span>
            </Tooltip>
          );
        })}
      </div>
    );
  }
  return null;
};

const ActivityEventItemView = () => {
  return (
    <ListItem style={{ padding: 2 }}>
      <ListItemAvatar>
        <UserPresentation />
      </ListItemAvatar>
      <ListItemText
        primary={
          <div
            style={{
              minWidth: 200,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <ActivityTypePresentation />
            <ActivityClaimActionPresentation />
          </div>
        }
        secondary={
          <>
            <DateField
              showTime
              source="activityDate"
              label="Activity Date"
              locales="de-DE"
              options={{
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZone: "Europe/Berlin",
              }}
              variant="body2"
            />
            <ActivityResultPresentation />
          </>
        }
      />
    </ListItem>
  );
};

export default ActivityEventItemView;
