import { AppBar, Layout, ReduxState, UserMenu } from "react-admin";
import { useSelector } from "react-redux";
// Custom UserMenu with Logout button
const CustomUserMenu = (props) => {
  return <UserMenu {...props}></UserMenu>;
};

// Custom AppBar that uses CustomUserMenu
const CustomAppBar = (props) => {
  return <AppBar {...props} userMenu={<CustomUserMenu />} />;
};

// Custom Layout that uses CustomAppBar
const CustomLayout = (props) => {
  const isSidebarOpen: boolean = useSelector(
    (state: ReduxState) => state.admin.ui.sidebarOpen
  );
  return (
    <Layout
      {...props}
      appBar={CustomAppBar}
      className={`${isSidebarOpen ? "-openSideBar" : "-closedSideBar"}`}
    >
      {props.children}
    </Layout>
  );
};

export default CustomLayout;
