import { Grid, Typography } from "@material-ui/core";

const GridHeader = () => {
  return (
    <Grid
      container
      style={{ padding: "10px 20px", alignItems: "center", minWidth: 910 }}
    >
      <Grid
        style={{
          maxWidth: "12%",
          flexBasis: "12%",
        }}
        item
      >
        <Typography variant={"caption"}>{"Reference"}</Typography>
      </Grid>
      <Grid
        style={{
          maxWidth: "18%",
          flexBasis: "18%",
        }}
        item
      >
        <Typography variant={"caption"}>{"Creditor"}</Typography>
      </Grid>
      <Grid
        style={{
          maxWidth: "19%",
          flexBasis: "19%",
        }}
        item
      >
        <Typography style={{ paddingLeft: "1rem" }} variant={"caption"}>
          {"Debtor"}
        </Typography>
      </Grid>
      <Grid
        style={{
          maxWidth: "24%",
          flexBasis: "24%",
        }}
        item
      >
        <Typography variant={"caption"}>
          {"Due / Last updated / Claim amount"}
        </Typography>
      </Grid>

      <Grid xs={1} item style={{ textAlign: "right" }}>
        <Typography variant={"caption"}>{"Note(s)"}</Typography>
      </Grid>
      <Grid xs={2} item style={{ textAlign: "right" }}>
        <Typography variant={"caption"}>{"Quick Actions"}</Typography>
      </Grid>
    </Grid>
  );
};

export default GridHeader;
