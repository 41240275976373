import Timer from "@material-ui/icons/Timer";
import {
  DateField,
  FunctionField,
  ReferenceField,
  useRecordContext,
} from "react-admin";
import { Activity } from "../../api/activity/Activity";
import { theme } from "../../theme/theme";

export const ActivityDateField = () => {
  const record: Activity = useRecordContext();

  return (
    <>
      {record?.isPlanned && (
        <div style={{ marginTop: 3, position: "relative" }}>
          <span
            style={{
              fontSize: 12,
              fontWeight: "bold",
              verticalAlign: "top",
              color: "var(--secondary)",
              lineHeight: "22px",
            }}
          >
            Geplant
          </span>
          <Timer
            fontSize={"small"}
            style={{
              width: 18,
              height: 18,
              marginLeft: 2,
              marginTop: 1,
              position: "absolute",
            }}
            color={"primary"}
          />
        </div>
      )}
      <DateField
        showTime
        source="activityDate"
        label="Activity Date"
        locales="de-DE"
        style={{
          whiteSpace: "nowrap",
          fontSize: 13,
          fontWeight: "bold",
          verticalAlign: "top",
          lineHeight: "22px",
        }}
        options={{
          month: "2-digit",
          day: "2-digit",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          timeZone: "Europe/Berlin",
        }}
      />
      <br />
      <ReferenceField link="show" label="" source="manager.id" reference="User">
        <FunctionField
          label="Manager"
          render={(record: any) => (
            <span
              style={{
                fontSize: 12,
                fontWeight: "bold",
                verticalAlign: "top",
                color: theme.palette.primary.dark,
                lineHeight: "22px",
              }}
            >
              {record?.username?.split("@")?.[0]}
            </span>
          )}
        />
      </ReferenceField>
    </>
  );
};
