export function stringToHslColor(input, lightLevel) {
  if (!input) {
    return "";
  }

  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  return "hsl(" + h + ", " + 75 + "%, " + lightLevel + "%)";
}
