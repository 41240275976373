import * as React from "react";
import { Create, CreateProps } from "react-admin";

export const ServiceCallCreate = (props: CreateProps): React.ReactElement => {
  return (
    <Create {...props}>
      {/*

      ¯\_(ツ)_/¯

      */}
      <div>No</div>
    </Create>
  );
};
