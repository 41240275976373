import {
  CircularProgress,
  Drawer,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CloseRounded from "@material-ui/icons/CloseRounded";
import Face from "@material-ui/icons/Face";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import {
  Button,
  LinearProgress,
  useNotify,
  useRecordContext,
} from "react-admin";
import { theme } from "../theme/theme";

export const ClaimDrawer = (): React.ReactElement => {
  const record = useRecordContext();

  const [isLoading, setIsLoading] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [showAIDrawer, setShowAIDrawer] = useState(false);
  const [AIMessages, setAIMessages] = useState<
    {
      header: string;
      content: string;
    }[]
  >([]);

  function handleAISolveButton(
    action:
      | "Summary"
      | "DebtorResponse"
      | "CreditorResponse"
      | "ProposedActionPlan",
    additionalContext?: string
  ): void {
    const authHeader = {
      headers: {
        Authorization: localStorage.getItem("credentials") || "",
        "Content-Type": "application/json",
      },
    };
    setIsLoading(true);
    setShowAIDrawer(true);

    const decoder = new TextDecoder();
    fetch(
      process.env.REACT_APP_SERVER_URL +
        "/api/claims/" +
        record?.id +
        "/autoSolve?action=" +
        action,
      {
        method: "POST",
        body: JSON.stringify({
          additionalContext: additionalContext,
        }),
        ...authHeader,
      }
    )
      // Retrieve its body as ReadableStream
      .then((response) => {
        const reader = response.body.getReader();
        let incompleteData = ""; // Store incomplete data chunks here

        return new ReadableStream({
          start(controller) {
            return pump();

            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  handleRealTimeData(incompleteData, true);
                  controller.close();
                  return;
                }

                const readable = decoder.decode(value, { stream: true });
                let data = incompleteData + readable;
                let lastNewlineIndex = data.lastIndexOf("\n\n");

                if (lastNewlineIndex !== -1) {
                  // Process complete messages
                  let completeData = data.substring(0, lastNewlineIndex + 1);
                  handleRealTimeData(completeData);
                  // Save incomplete chunk for next round
                  incompleteData = data.substring(lastNewlineIndex + 2);
                } else {
                  // No complete messages, save all as incomplete
                  incompleteData += readable;
                }

                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      .catch((err) => console.error(err));
  }

  function handleRealTimeData(dataString: string, isDone: boolean = false) {
    let newMessage = ""; // Accumulate new messages here
    let lines = dataString.split("\n");

    for (const line of lines) {
      if (line.startsWith("data: ")) {
        let actualData = line.substring(6);
        if (actualData) {
          newMessage += actualData
            .replace(/^"|"$/g, "")
            .replace(/\\n/g, "\n")
            .replace(/\\"/g, '"');
        }
      }
    }

    // Update state with all the new messages
    if (newMessage) {
      setCurrentMessage((prevMessage) => prevMessage + newMessage);
    }
    if (isDone) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!isLoading && currentMessage) {
      // Now you should use the functional form of setAIMessages to access the updated state
      setAIMessages((prevAIMessages) => {
        const date = new Date();
        return [
          ...prevAIMessages,
          {
            header: `Der Roboter (${format(date, "HH:mm")})`,
            content: currentMessage || "Error generating response",
          },
        ];
      });
      setCurrentMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const notify = useNotify();
  return (
    <>
      <div
        onClick={() => {
          setShowAIDrawer(true);
          if (AIMessages.length === 0) {
            handleAISolveButton("Summary");
          }
        }}
        style={{
          position: "fixed",
          background:
            "linear-gradient(211.49deg,#0f82e0 15.89%,#5229c5 48.97%)",
          color: "#fff",
          borderRadius: "34px 8px 34px 34px",
          padding: 10,
          paddingLeft: 15,
          paddingRight: 15,
          bottom: 20,
          right: 20,
          boxShadow: "0 5px 4px 0 rgba(0,0,0,.26)",
          cursor: "pointer",
          zIndex: 999,
        }}
      >
        <Face style={{ width: 30, height: 30 }} />
      </div>

      <Drawer
        anchor={"right"}
        open={showAIDrawer}
        variant="persistent"
        PaperProps={{
          style: {
            background: "#fff",
            paddingTop: 40,
            height: "100%",
            boxSizing: "border-box",
            boxShadow: "0 5px 4px 0 rgba(0,0,0,.15)",
          },
        }}
        style={{ width: 350 }}
        onClose={() => setShowAIDrawer(false)}
        ModalProps={{
          BackdropProps: {
            invisible: true,
          },
        }}
      >
        <div
          style={{
            width: 350,
            padding: 20,
            paddingTop: 60,
            position: "relative",
          }}
        >
          <CloseRounded
            style={{
              position: "absolute",
              right: 20,
              top: 15,
              background: theme.palette.grey[100],
              borderRadius: "100%",
              cursor: "pointer",
              padding: 10,
            }}
            onClick={() => setShowAIDrawer(false)}
          />
          {AIMessages?.length === 0 && !isLoading ? (
            <Typography variant="subtitle1" style={{ height: 40 }}>
              <Face
                style={{
                  background:
                    "linear-gradient(211.49deg,#0f82e0 15.89%,#5229c5 48.97%)",
                  color: "#fff",
                  height: 18,
                  width: 18,
                  borderRadius: 18,
                  verticalAlign: "middle",
                  padding: 5,
                  marginRight: 8,
                  marginBottom: 3,
                }}
              />
              How can I help?
            </Typography>
          ) : (
            ""
          )}
          {AIMessages.map((message, index) => (
            <div
              key={index}
              style={{
                backgroundColor: "#f0f0f0",
                padding: 12,
                margin: "15px 0 0",
                borderRadius: "10px",
              }}
            >
              <Typography variant="subtitle1" style={{ height: 40 }}>
                <Face
                  style={{
                    background:
                      "linear-gradient(211.49deg,#0f82e0 15.89%,#5229c5 48.97%)",
                    color: "#fff",
                    height: 18,
                    width: 18,
                    borderRadius: 18,
                    verticalAlign: "middle",
                    padding: 5,
                    marginRight: 8,
                    marginBottom: 3,
                  }}
                />
                {message.header}
                <Tooltip title="Copy message">
                  <FileCopyOutlined
                    style={{
                      height: 16,
                      cursor: "copy",
                      color: "var(--black10)",
                      padding: 8,
                      verticalAlign: "middle",
                      float: "right",
                      zIndex: 3,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(message.content);
                      notify("Copied to clipboard", "info", null, false);
                    }}
                  />
                </Tooltip>
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  wordBreak: "break-word",
                  whiteSpace: "pre-wrap",
                }}
              >
                {message.content}
              </Typography>
            </div>
          ))}
          {isLoading && (
            <div
              key={"loading"}
              style={{
                backgroundColor: "#f0f0f0",
                padding: 12,
                margin: "15px 0 0",
                borderRadius: "10px",
              }}
            >
              <Typography variant="subtitle1" style={{ height: 40 }}>
                <Face
                  style={{
                    background:
                      "linear-gradient(211.49deg,#0f82e0 15.89%,#5229c5 48.97%)",
                    color: "#fff",
                    height: 18,
                    width: 18,
                    borderRadius: 18,
                    verticalAlign: "middle",
                    padding: 5,
                    marginRight: 8,
                    marginBottom: 3,
                  }}
                />
                {"Der Roboter"}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  wordBreak: "break-word",
                  whiteSpace: "pre-wrap",
                }}
              >
                {currentMessage || "Generating..."}
                <LinearProgress
                  style={{
                    width: "90%",
                    margin: "10px auto 10px",
                    borderRadius: 4,
                  }}
                  color={"secondary"}
                />
              </Typography>
            </div>
          )}
          {AIMessages.length === 0 && !isLoading && (
            <>
              <Button
                variant="outlined"
                size="medium"
                disabled={isLoading}
                fullWidth
                startIcon={
                  isLoading ? (
                    <CircularProgress style={{ color: "red" }} size={12} />
                  ) : (
                    ""
                  )
                }
                style={{
                  marginTop: 15,
                }}
                onClick={() => handleAISolveButton("Summary")}
                label="🧠 AI explanation ✨"
              />
            </>
          )}
          {AIMessages.length === 1 && !isLoading && (
            <Button
              variant="outlined"
              size="medium"
              fullWidth
              disabled={isLoading}
              startIcon={
                isLoading ? (
                  <CircularProgress style={{ color: "red" }} size={12} />
                ) : (
                  ""
                )
              }
              style={{ marginTop: 15 }}
              onClick={() =>
                handleAISolveButton(
                  "ProposedActionPlan",
                  AIMessages.filter((message) =>
                    message.header.startsWith("Der Roboter")
                  ).slice(-1)[0]?.content
                )
              }
              label="✅ Let's do this! ✨"
            />
          )}
          {AIMessages.length > 1 && !isLoading && (
            <Button
              variant="outlined"
              size="medium"
              fullWidth
              disabled={isLoading}
              startIcon={
                isLoading ? (
                  <CircularProgress style={{ color: "red" }} size={12} />
                ) : (
                  ""
                )
              }
              style={{ marginTop: 15 }}
              onClick={() =>
                handleAISolveButton(
                  "DebtorResponse",
                  AIMessages.filter(
                    (message) =>
                      message.header.startsWith("Der Roboter") &&
                      message.content
                  )
                    .map((message) => message.content + "### New message: \n")
                    .join("New message: \n")
                )
              }
              label="✍️ New AI debtor reply ✨"
            />
          )}
        </div>
      </Drawer>
    </>
  );
};
