import qs from "qs";
import * as React from "react";
import {
  Datagrid,
  DateField,
  List,
  ListProps,
  ReferenceField,
  SearchInput,
  TextField,
} from "react-admin";
import { useLocation } from "react-router-dom";
import Pagination from "../Components/Pagination";
import { theme } from "../theme/theme";
import { USER_TITLE_FIELD } from "../user/UserTitle";

export const AddressList = (props: ListProps): React.ReactElement => {
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  let jsonFilter: any = {
    addressLine1: "",
  };

  if (parsedLocation.filter) {
    try {
      jsonFilter = JSON.parse(parsedLocation.filter as string);
    } catch (e) {}
  }

  const [addressLine1FilterValue, setAddressLine1FilterValue] = React.useState(
    jsonFilter.addressLine1?.toString() || undefined
  );
  const [postalcodeFilterValue, setPostalcodeFilterValue] = React.useState(
    jsonFilter.postalcode?.toString() || undefined
  );
  const [townFilterValue, setTownFilterValue] = React.useState(
    jsonFilter.town?.toString() || undefined
  );

  const addressFilters = [
    <SearchInput
      filterToQuery={(searchText) => ({
        addressLine1: { startsWith: searchText },
      })}
      source="addressLine1"
      alwaysOn
      placeholder="Address Line"
      autoFocus
      onChange={(event) => {
        if (event.target?.value && event.target.value.length > 2) {
          setAddressLine1FilterValue(event?.target.value);
        } else {
          setAddressLine1FilterValue(undefined);
        }
      }}
      fullWidth
      style={{ minWidth: 260 }}
    />,
    <SearchInput
      filterToQuery={(searchText) => ({
        postalcode: { startsWith: searchText },
      })}
      source="postalcode"
      alwaysOn
      placeholder="Postalcode"
      autoFocus
      onChange={(event) => {
        if (event.target?.value && event.target.value.length > 2) {
          setPostalcodeFilterValue(event?.target.value);
        } else {
          setPostalcodeFilterValue(undefined);
        }
      }}
      fullWidth
      style={{ minWidth: 260 }}
    />,
    <SearchInput
      filterToQuery={(searchText) => ({
        town: { startsWith: searchText },
      })}
      source="town"
      alwaysOn
      placeholder="Town"
      autoFocus
      onChange={(event) => {
        if (event.target?.value && event.target.value.length > 2) {
          setTownFilterValue(event?.target.value);
        } else {
          setTownFilterValue(undefined);
        }
      }}
      fullWidth
      style={{ minWidth: 260 }}
    />,
  ];

  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"Addresses"}
      filter={{
        ...(!!addressLine1FilterValue
          ? {
              addressLine1: { contains: addressLine1FilterValue || "" },
            }
          : {
              addressLine1: { not: undefined },
            }),
        ...(!!postalcodeFilterValue
          ? {
              postalcode: { contains: postalcodeFilterValue || "" },
            }
          : {
              postalcode: { not: undefined },
            }),
        ...(!!townFilterValue
          ? {
              town: { contains: townFilterValue || "" },
            }
          : {
              town: { not: undefined },
            }),
      }}
      filters={addressFilters}
      exporter={false}
      perPage={50}
      empty={false}
      pagination={<Pagination />}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <Datagrid
        rowClick="show"
        optimized
        size="medium"
        rowStyle={(_record, index) => ({
          backgroundColor: index % 2 === 0 ? "white" : theme.palette.grey[100],
        })}
        style={{
          marginTop: "1rem",
        }}
      >
        <TextField label="Address Line 1" source="addressLine1" />
        <TextField label="Address Line 2" source="addressLine2" />
        <TextField label="Postalcode" source="postalcode" />
        <TextField label="Town" source="town" />
        <TextField label="Country" source="country" />
        <DateField
          source="createdAt"
          label="Created At"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
        <DateField
          source="updatedAt"
          label="Updated At"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
        <ReferenceField
          label="User"
          source="user.id"
          reference="User"
          link="show"
        >
          <TextField source={USER_TITLE_FIELD} />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
