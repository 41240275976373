import * as React from "react";
import {
  DateField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { USER_TITLE_FIELD } from "../user/UserTitle";

export const ServiceCallShow = (props: ShowProps): React.ReactElement => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <DateField
          source="createdAt"
          label="Created At"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
        <TextField label="ID" source="id" />
        <TextField label="Type" source="serviceCallType" />
        <TextField label="Type" source="serviceCallSubType" />
        <DateField
          source="updatedAt"
          label="Updated At"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
        <ReferenceField label="User" source="user.id" reference="User">
          <TextField source={USER_TITLE_FIELD} />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};
