import { Typography } from "@material-ui/core";
import { Close, Explore, Gavel, RepeatRounded } from "@material-ui/icons";
import Add from "@material-ui/icons/Add";
import { useState } from "react";
import {
  Button,
  TopToolbar,
  useGetIdentity,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { EnumActivityClaimAction } from "../api/activity/EnumActivityClaimAction";
import { OPS_ELEVATED_RIGHT_USERS } from "../config/AppConfig";
import { theme } from "../theme/theme";

export const ClaimActivityTools = (props: any): React.ReactElement => {
  const record = useRecordContext();
  const refresh = useRefresh();

  const [isLoading, setIsLoading] = useState(false);
  const { identity } = useGetIdentity();
  // this is a maze of if statements - works now, cry later
  const enableCourtHandover =
    OPS_ELEVATED_RIGHT_USERS.includes(identity?.username.toString()) &&
    ["Court", "Precourt"].includes(record?.stage) &&
    props.showActivityAdd &&
    !props.creditor?.disableCourtOrder &&
    ((props.debtor?.businessType !== "Business" &&
      (!props.creditor?.courtOrderMinimumB2C ||
        (props.creditor?.courtOrderMinimumB2C &&
          props.creditor?.courtOrderMinimumB2C < record?.originalAmountDue))) ||
      (props.debtor?.businessType === "Business" &&
        props.debtor?.businessName &&
        props.debtor?.contactName &&
        (!props.creditor?.courtOrderMinimumB2B ||
          (props.creditor?.courtOrderMinimumB2B &&
            props.creditor?.courtOrderMinimumB2B <
              record?.originalAmountDue))));

  const enableResearch =
    ["Court", "Precourt"].includes(record?.stage) &&
    props.showActivityAdd &&
    !props.creditor?.disableRegistrationOfficeEnquiry;

  const enableRecalculation =
    ["Court", "Precourt"].includes(record?.stage) && props.showActivityAdd;

  function handleClaimAction(
    action: "PaymentOrder" | "Research" | "Recalculate" = "PaymentOrder",
    claimAction?: EnumActivityClaimAction
  ): void {
    const authHeader = {
      headers: {
        Authorization: localStorage.getItem("credentials") || "",
        "Content-Type": "application/json",
      },
    };

    if (window.confirm("Are you sure? This cannot be undone")) {
      setIsLoading(true);
      fetch(
        process.env.REACT_APP_SERVER_URL +
          "/api/claims/" +
          record?.id +
          "/claimAction?action=" +
          action +
          (claimAction ? "&claimAction=" + claimAction : ""),
        {
          method: "POST",
          ...authHeader,
        }
      )
        .then(async (response) => {
          const json = await response.json();
          if (!json) {
            alert(
              "This did not work correctly. Please notify the IT Gang 🥷🏻🥷🏻"
            );
          }
          setIsLoading(false);

          refresh(true);
        })
        .catch((err) => console.error(err));
    }
  }

  const savedComments: string = record?.comment || "";

  return (
    <TopToolbar>
      <div className="topG-toolbar">
        <div className="row">
          <div className="comment-wrapper">
            {savedComments && savedComments.length > 255 ? (
              <Typography
                variant="body2"
                style={{
                  maxWidth: "50rem",
                  overflow: "auto",
                  whiteSpace: "pre",
                }}
              >
                {savedComments.substr(0, 255) + " "}
                <a
                  style={{ fontSize: 14, color: theme.palette.grey[700] }}
                  href={"/#/Claim/" + record?.id + "/show/4"}
                >
                  ...more
                </a>
              </Typography>
            ) : (
              <Typography
                variant="body2"
                style={{
                  maxWidth: "50rem",
                  overflow: "auto",
                  whiteSpace: "pre",
                }}
              >
                {savedComments}
              </Typography>
            )}
          </div>
          <div className="dog">
            <Button
              size="medium"
              className={`toggle-btn ${
                props.showActivityAdd ? "open" : "close"
              }`}
              href={"/#/Claim/" + record?.id + "/show/2"}
              startIcon={props.showActivityAdd ? <Close /> : <Add />}
              onClick={() => {
                props.onCreateButtonClick();
              }}
              label={props.showActivityAdd ? "Cancel" : "New Activity"}
            />
          </div>
        </div>

        <div
          className={`claim-court-buttons ${
            props.showActivityAdd ? "show" : ""
          }`}
        >
          {enableCourtHandover && (
            <Button
              disabled={isLoading}
              size="medium"
              onClick={() => handleClaimAction("PaymentOrder")}
              startIcon={<Gavel />}
              label="Start Court Process"
            />
          )}
          {enableResearch && (
            <Button
              disabled={isLoading}
              size="medium"
              onClick={() => handleClaimAction("Research")}
              startIcon={<Explore />}
              label="Authority Research"
            />
          )}
          {enableRecalculation && (
            <Button
              disabled={isLoading}
              size="medium"
              onClick={() => handleClaimAction("Recalculate")}
              startIcon={<RepeatRounded />}
              label="Recalculate"
            />
          )}
        </div>
      </div>
    </TopToolbar>
  );
};
