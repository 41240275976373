import { Activity } from "../api/activity/Activity";

const DateParser = require("any-date-parser");

export function getDaysInMonth(monthValue) {
  const year = new Date().getFullYear();
  const month = monthValue + 1; // Adding 1 because the month value starts from 0

  const numDays = new Date(year, month, 0).getDate();
  const days = [];

  for (let i = 1; i <= numDays; i++) {
    days.push({
      label: i.toString(),
      value: i,
    });
  }

  return days;
}

export const parseDate = (dateString: string): Date | null => {
  let date = new Date();
  try {
    date = DateParser.fromString(dateString);
    if (isNaN(date.getTime())) {
      throw new Error("unable to auto detect date using any-date-p");
    }
  } catch (e) {
    date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return null;
    }
    return date;
  }
  return date;
};

export const medianActivityDateDifferenceInMinutes = (
  activities: Activity[]
): number => {
  const sortedDifferences = activities
    .map((call, index) => {
      if (index === 0) {
        return 0;
      }
      const prevActivityDate = new Date(
        activities[index - 1].activityDate as Date
      );
      const currentActivityDate = new Date(call.activityDate as Date);
      const differenceInMilliseconds =
        currentActivityDate.getTime() - prevActivityDate.getTime();
      return differenceInMilliseconds / (1000 * 60); // Convert milliseconds to minutes
    })
    .sort((a, b) => a - b);

  const middleIndex = Math.floor(sortedDifferences.length / 2);

  let result: number = 0;
  if (sortedDifferences.length % 2 === 0) {
    result =
      (sortedDifferences[middleIndex - 1] + sortedDifferences[middleIndex]) / 2;
  } else {
    result = sortedDifferences[middleIndex];
  }
  return parseFloat(result.toFixed(2));
};

const date: Date = new Date();
date.setHours(23, 59, 59, 59);
export const TODAY = date;
