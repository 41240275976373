import { LinearProgress } from "@material-ui/core";
import {
  AccountBalanceRounded,
  AccountBoxRounded,
  BusinessCenterRounded,
  EmailRounded,
  EventRounded,
  LinkRounded,
  PaymentRounded,
  PlaceRounded,
  WifiRounded,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Admin, DataProvider, Loading, Resource } from "react-admin";
import { Route } from "react-router-dom";
import "./App.scss";
import CustomLayout from "./Components/layout/CustomLayout";
import Login from "./Login";
import Menu from "./Menu";
import { ActivityCreate } from "./activity/ActivityCreate";
import { ActivityEdit } from "./activity/ActivityEdit";
import { ActivityList } from "./activity/ActivityList";
import { ActivityShow } from "./activity/ActivityShow";
import { AddressCreate } from "./address/AddressCreate";
import { AddressEdit } from "./address/AddressEdit";
import { AddressList } from "./address/AddressList";
import { AddressShow } from "./address/AddressShow";
import { jwtAuthProvider } from "./auth-provider/ra-auth-jwt";
import { ClaimCreate } from "./claim/ClaimCreate";
import { ClaimEdit } from "./claim/ClaimEdit";
import { ClaimList } from "./claim/ClaimList";
import { ClaimShow } from "./claim/ClaimShow";
import buildGraphQLProvider, {
  cacheDataProvider,
} from "./data-provider/graphqlDataProvider";
import { IntegrationCreate } from "./integration/IntegrationCreate";
import { IntegrationEdit } from "./integration/IntegrationEdit";
import { IntegrationList } from "./integration/IntegrationList";
import { IntegrationShow } from "./integration/IntegrationShow";
import Dashboard from "./pages/Dashboard";
import OperationsOverview from "./pages/OperationsOverview";
import PaymentOverview from "./pages/PaymentOverview";
import { PaymentCreate } from "./payment/PaymentCreate";
import { PaymentEdit } from "./payment/PaymentEdit";
import { PaymentList } from "./payment/PaymentList";
import { PaymentShow } from "./payment/PaymentShow";
import { PaymentInformationCreate } from "./paymentInformation/PaymentInformationCreate";
import { PaymentInformationEdit } from "./paymentInformation/PaymentInformationEdit";
import { PaymentInformationList } from "./paymentInformation/PaymentInformationList";
import { PaymentInformationShow } from "./paymentInformation/PaymentInformationShow";
import { PaymentPlanCreate } from "./paymentPlan/PaymentPlanCreate";
import { PaymentPlanEdit } from "./paymentPlan/PaymentPlanEdit";
import { PaymentPlanList } from "./paymentPlan/PaymentPlanList";
import { PaymentPlanShow } from "./paymentPlan/PaymentPlanShow";
import { ServiceCallCreate } from "./serviceCall/ServiceCallCreate";
import { ServiceCallEdit } from "./serviceCall/ServiceCallEdit";
import { ServiceCallList } from "./serviceCall/ServiceCallList";
import { ServiceCallShow } from "./serviceCall/ServiceCallShow";
import { theme } from "./theme/theme";
import { UserCreate } from "./user/UserCreate";
import { UserEdit } from "./user/UserEdit";
import { UserList } from "./user/UserList";
import { UserShow } from "./user/UserShow";

const App = (): React.ReactElement => {
  const [dataProvider, setDataProvider] = useState<DataProvider | null>(null);

  useEffect(() => {
    buildGraphQLProvider
      .then((provider: any) => {
        setDataProvider(() => cacheDataProvider(provider));
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  if (!dataProvider) {
    return (
      <>
        <LinearProgress variant={"buffer"} />
        <Loading
          loadingPrimary="Loading Management Dashboard"
          loadingSecondary="Nearly there..."
        />
      </>
    );
  }

  return (
    <div className="App">
      <Admin
        title={"Debtist"}
        dataProvider={dataProvider}
        authProvider={jwtAuthProvider}
        theme={theme}
        dashboard={Dashboard}
        customRoutes={[
          <Route exact path="/Home" component={Dashboard} />,
          <Route exact path="/PaymentOverview" component={PaymentOverview} />,
          <Route
            exact
            path="/OperationsOverview"
            component={OperationsOverview}
          />,
        ]}
        menu={Menu}
        loginPage={Login}
        disableTelemetry
        layout={CustomLayout}
      >
        <Resource
          name="Claim"
          list={ClaimList}
          edit={ClaimEdit}
          create={ClaimCreate}
          show={ClaimShow}
          icon={() => <BusinessCenterRounded />}
        />
        <Resource
          name="User"
          list={UserList}
          edit={UserEdit}
          create={UserCreate}
          show={UserShow}
          icon={() => <AccountBoxRounded />}
        />
        <Resource
          name="Activity"
          options={{ label: "- Activity" }}
          list={ActivityList}
          edit={ActivityEdit}
          create={ActivityCreate}
          show={ActivityShow}
          icon={() => <EmailRounded />}
        />
        <Resource
          name="Address"
          options={{ label: "- Address" }}
          list={AddressList}
          edit={AddressEdit}
          create={AddressCreate}
          show={AddressShow}
          icon={() => <PlaceRounded />}
        />
        <Resource
          name="Payment"
          options={{ label: "- Payment" }}
          list={PaymentList}
          edit={PaymentEdit}
          create={PaymentCreate}
          show={PaymentShow}
          icon={() => <PaymentRounded />}
        />
        <Resource
          name="PaymentInformation"
          options={{ label: "- Payment Info" }}
          list={PaymentInformationList}
          edit={PaymentInformationEdit}
          create={PaymentInformationCreate}
          show={PaymentInformationShow}
          icon={() => <AccountBalanceRounded />}
        />
        <Resource
          name="PaymentPlan"
          options={{ label: "- Payment Plan" }}
          list={PaymentPlanList}
          edit={PaymentPlanEdit}
          create={PaymentPlanCreate}
          show={PaymentPlanShow}
          icon={() => <EventRounded />}
        />
        <Resource
          name="Integration"
          options={{ label: "- Integration" }}
          list={IntegrationList}
          edit={IntegrationEdit}
          create={IntegrationCreate}
          show={IntegrationShow}
          icon={() => <LinkRounded />}
        />
        <Resource
          name="ServiceCall"
          options={{ label: "- Service Call" }}
          list={ServiceCallList}
          edit={ServiceCallEdit}
          create={ServiceCallCreate}
          show={ServiceCallShow}
          icon={() => <WifiRounded />}
        />
      </Admin>
    </div>
  );
};

export default App;
