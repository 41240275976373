import Event from "@material-ui/icons/Event";
import React from "react";
import { useListContext } from "react-admin";
import { PaymentPlan } from "../api/paymentPlan/PaymentPlan";
import { theme } from "../theme/theme";

export const PaymentPlanSummary = React.memo(() => {
  const { data, loading } = useListContext();
  const paymentPlans: PaymentPlan[] = React.useMemo(
    () => (Object.values(data) as PaymentPlan[]) || [],
    [data]
  );

  if (loading || !paymentPlans || !paymentPlans.length) {
    return null;
  }

  const paymentPlan = paymentPlans[0];
  const isActive = paymentPlan?.isActive;

  return (
    <span
      title="Payment Plan Active"
      style={{
        color: isActive ? theme.palette.success.main : theme.palette.grey[500],
        fontSize: 10,
        display: "flex",
        alignItems: "flex-end",
      }}
    >
      <Event style={{ width: "auto", height: 18 }} />
      {"x" + paymentPlan.numberOfPayments}
    </span>
  );
});
