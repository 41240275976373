import { Grid, Hidden } from "@material-ui/core";
import { ReduxState } from "react-admin";
import { useSelector } from "react-redux";

// todo
export const Logo = () => {
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const domainName = window?.location?.hostname;
  let productName = "Webmanagement";
  if (domainName.includes("localhost")) {
    productName = productName + " DEV";
  }

  return (
    <Grid container spacing={1} direction="row" alignItems="center">
      <Hidden xsDown>
        <Grid item sm={12}>
          {open && (
            <a href="/Home" className={`logoBadge `}>
              {""}
            </a>
          )}

          {open && <div className={`logoText`}>{productName}</div>}
        </Grid>
      </Hidden>
    </Grid>
  );
};
