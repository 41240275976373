import {
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { useCallback, useEffect } from "react";
import {
  BooleanField,
  DateField,
  NumberField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { theme } from "../theme/theme";
import { USER_TITLE_FIELD } from "../user/UserTitle";

export const IntegrationShow = (props: ShowProps): React.ReactElement => {
  const handleKeyPress = useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/Integration/" + props.id + "/edit", "_self");
      }
    },
    [props.id]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Grid container spacing={2} style={{ width: "100%" }}>
          <Grid item xs={12} md={6}>
            <List>
              <ListItem alignItems={"flex-start"}>
                <Typography variant="h6">{"User Details:"}</Typography>
              </ListItem>

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={<Typography variant="caption">{"ID:"}</Typography>}
                  secondary={<TextField label="ID" source="id" />}
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={<Typography variant="caption">{"User:"}</Typography>}
                  secondary={
                    <ReferenceField
                      label="User"
                      source="user.id"
                      reference="User"
                    >
                      <TextField source={USER_TITLE_FIELD} />
                    </ReferenceField>
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">
                      {"User Identifier:"}
                    </Typography>
                  }
                  secondary={
                    <TextField
                      label="User Identifier"
                      source="userIdentifier"
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">
                      {"Organization Identifier:"}
                    </Typography>
                  }
                  secondary={
                    <TextField
                      label="Organization Identifier"
                      source="organizationIdentifier"
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">
                      {"Domain Identifier:"}
                    </Typography>
                  }
                  secondary={
                    <TextField
                      label="Domain Identifier"
                      source="domainIdentifier"
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">
                      {"Database Identifier:"}
                    </Typography>
                  }
                  secondary={
                    <TextField
                      label="Database Identifier"
                      source="databaseIdentifier"
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">
                      {"Custom Email Address:"}
                    </Typography>
                  }
                  secondary={
                    <TextField
                      label="Custom Email Address"
                      source="customEmailAddress"
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">
                      {"Custom Email Selector:"}
                    </Typography>
                  }
                  secondary={
                    <TextField
                      label="Custom Email Selector"
                      source="customEmailAddressSelector"
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">
                      {"Reply-To Email Address:"}
                    </Typography>
                  }
                  secondary={
                    <TextField
                      label="Reply-To Email Address"
                      source="replyToEmailAddress"
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">
                      {"Custom Email Key:"}
                    </Typography>
                  }
                  secondary={
                    <TextField
                      label="Custom Email Key"
                      source="customEmailAddressKey"
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">
                      {"B2C Identifier:"}
                    </Typography>
                  }
                  secondary={
                    <TextField label="B2C Identifier" source="b2cIdentifier" />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">
                      {"B2B Identifier:"}
                    </Typography>
                  }
                  secondary={
                    <TextField label="B2B Identifier" source="b2bIdentifier" />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">
                      {"Days overdue to fetch (Dunning):"}
                    </Typography>
                  }
                  secondary={
                    <NumberField
                      label="Days overdue to fetch (Dunning)"
                      source="daysOverDueToFetch"
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">
                      {"Days due to fetch for first reminder:"}
                    </Typography>
                  }
                  secondary={
                    <NumberField
                      label="Days due to fetch for first reminder"
                      source="daysDueToFetchForFirstReminder"
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">
                      {"Days due to fetch for second reminder:"}
                    </Typography>
                  }
                  secondary={
                    <NumberField
                      label="Days due to fetch for second reminder"
                      source="daysDueToFetchForSecondReminder"
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />
              {/* These DAY REMINDERS can maybe be moved to Timestamps, what say you Brandino? */}
              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">
                      {"Days due to fetch for third reminder:"}
                    </Typography>
                  }
                  secondary={
                    <NumberField
                      label="Days due to fetch for third reminder"
                      source="daysDueToFetchForThirdReminder"
                    />
                  }
                ></ListItemText>
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} md={6}>
            <List>
              <ListItem alignItems={"flex-start"}>
                <Typography variant="h6">
                  {"Integration Information:"}
                </Typography>
              </ListItem>

              <ListItem alignItems={"flex-start"}>
                <Card
                  variant="outlined"
                  style={{ background: theme.palette.grey[100], width: "100%" }}
                >
                  <CardContent className="content-flexCol">
                    <Typography variant="caption">{"Software:"}</Typography>
                    <TextField
                      label="Software"
                      source="software"
                      color={"primary"}
                    />
                  </CardContent>
                </Card>
              </ListItem>

              <ListItem alignItems={"flex-start"}>
                <Card
                  variant="outlined"
                  style={{ background: theme.palette.grey[100], width: "100%" }}
                >
                  <CardContent className="content-flexCol">
                    <Typography variant="caption">{"Token:"}</Typography>
                    <TextField
                      label="Token"
                      source="token"
                      color={"secondary"}
                      style={{
                        maxWidth: 380,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    />
                  </CardContent>
                </Card>
              </ListItem>

              <ListItem alignItems={"flex-start"}>
                <Card
                  variant="outlined"
                  style={{ background: theme.palette.grey[100], width: "100%" }}
                >
                  <CardContent className="content-flexCol">
                    <Typography variant="caption">{"Is Active:"}</Typography>
                    <BooleanField label="Is Active" source="isActive" />
                  </CardContent>
                </Card>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <List>
              <ListItem alignItems={"flex-start"}>
                <Typography variant="h6">{"Timestamps:"}</Typography>
              </ListItem>

              <ListItem alignItems={"flex-start"}>
                <Card
                  variant="outlined"
                  style={{ background: theme.palette.grey[100], width: "100%" }}
                >
                  <CardContent className="content-flexCol">
                    <Typography variant="caption">Created At:</Typography>
                    <DateField
                      source="createdAt"
                      label="Created At"
                      locales="de-DE"
                      options={{
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        timeZone: "Europe/Berlin",
                      }}
                    />
                  </CardContent>
                </Card>
              </ListItem>
              <ListItem alignItems={"flex-start"}>
                <Card
                  variant="outlined"
                  style={{ background: theme.palette.grey[100], width: "100%" }}
                >
                  <CardContent className="content-flexCol">
                    <Typography variant="caption">{"Updated At:"}</Typography>
                    <DateField
                      source="updatedAt"
                      label="Updated At"
                      locales="de-DE"
                      options={{
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        timeZone: "Europe/Berlin",
                      }}
                    />
                  </CardContent>
                </Card>
              </ListItem>

              <ListItem alignItems={"flex-start"}>
                <Card
                  variant="outlined"
                  style={{ background: theme.palette.grey[100], width: "100%" }}
                >
                  <CardContent className="content-flexCol">
                    <Typography variant="caption">
                      {"Last Sync Date:"}
                    </Typography>
                    <TextField label="Last Sync Date" source="lastSyncDate" />
                  </CardContent>
                </Card>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};
