import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import * as React from "react";
import { useCallback, useEffect } from "react";
import {
  BooleanField,
  Button,
  DateField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import { Claim } from "../api/claim/Claim";
import { Payment } from "../api/payment/Payment";
import { PAYMENTINFORMATION_TITLE_FIELD } from "../paymentInformation/PaymentInformationTitle";
import { PAYMENTPLAN_TITLE_FIELD } from "../paymentPlan/PaymentPlanTitle";

let claimReference: string | null = "";

function handleFileClick(claimReference: string, url: string): void {
  const urlToFetch = url
    .split("media/" + claimReference.replaceAll("/", "-") + "/")
    .pop();

  const authHeader = {
    headers: { Authorization: localStorage.getItem("credentials") || "" },
  };

  if (urlToFetch) {
    fetch(
      process.env.REACT_APP_SERVER_URL +
        "/api/claims/" +
        claimReference.replaceAll("/", "-") +
        "/file/" +
        urlToFetch,
      authHeader
    ).then((response) => {
      if (response.ok) {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);

        response.blob().then((blobby) => {
          const objectUrl = window.URL.createObjectURL(blobby);
          anchor.href = objectUrl;
          anchor.download = claimReference + "-" + urlToFetch;
          anchor.click();

          window.URL.revokeObjectURL(objectUrl);
        });
      }
    });
  }
}

const PaymentFileUrl = (): React.ReactElement => {
  const record: Payment = useRecordContext();

  return (
    <>
      <TextField label="File URL" source="fileUrl" />
      {record?.fileUrl &&
        record.fileUrl !== null &&
        claimReference !== null && (
          <Button
            variant="outlined"
            size="medium"
            startIcon={<ArrowDownward />}
            style={{ marginLeft: 10 }}
            onClick={() => handleFileClick(claimReference!, record.fileUrl!)}
            label="Download File"
          />
        )}
    </>
  );
};

const ClaimReference = (): React.ReactElement => {
  const record: Claim = useRecordContext();

  useEffect(() => {
    claimReference = record?.reference;
  }, [record?.reference]);

  return (
    <>
      <TextField source="reference" />
    </>
  );
};

export const PaymentShow = (props: ShowProps): React.ReactElement => {
  const handleKeyPress = useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/Payment/" + props.id + "/edit", "_self");
      }
    },
    [props.id]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <List>
              <ListItem alignItems={"flex-start"}>
                <Typography variant="h6">{"Payment Details:"}</Typography>
              </ListItem>
              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={<Typography variant="caption">Date:</Typography>}
                  secondary={
                    <DateField
                      label="Payment Date"
                      source="paymentDate"
                      locales="de-DE"
                      options={{
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        timeZone: "Europe/Berlin",
                      }}
                      style={{ fontWeight: 600 }}
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={<Typography variant="caption">Claim:</Typography>}
                  secondary={
                    <ReferenceField
                      link="show"
                      label="Claim"
                      source="claim.id"
                      reference="Claim"
                    >
                      <ClaimReference />
                    </ReferenceField>
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">Reference:</Typography>
                  }
                  secondary={
                    <TextField
                      label="Reference"
                      source="reference"
                      style={{ fontWeight: 600 }}
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={<Typography variant="caption">ID:</Typography>}
                  secondary={
                    <TextField
                      label="ID"
                      source="id"
                      style={{ fontWeight: 600 }}
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={<Typography variant="caption">Type:</Typography>}
                  secondary={
                    <TextField
                      label="Payment Type"
                      source="paymentType"
                      style={{ fontWeight: 600 }}
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={<Typography variant="caption">Amount:</Typography>}
                  secondary={
                    <TextField
                      label="Amount"
                      source="amount"
                      style={{ fontWeight: 600, color: "green" }}
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={<Typography variant="caption">Plan:</Typography>}
                  secondary={
                    <ReferenceField
                      link="show"
                      label="Payment Plan"
                      source="paymentPlan.id"
                      reference="PaymentPlan"
                    >
                      <TextField source={PAYMENTPLAN_TITLE_FIELD} />
                    </ReferenceField>
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">Created at:</Typography>
                  }
                  secondary={
                    <DateField
                      source="createdAt"
                      label="Created At"
                      locales="de-DE"
                      options={{
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        timeZone: "Europe/Berlin",
                      }}
                      style={{ fontWeight: 600 }}
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">Updated at:</Typography>
                  }
                  secondary={
                    <DateField
                      source="updatedAt"
                      label="Updated At"
                      locales="de-DE"
                      options={{
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        timeZone: "Europe/Berlin",
                      }}
                    />
                  }
                ></ListItemText>
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} md={7}>
            <List>
              <ListItem
                alignItems={"flex-start"}
                style={{ height: 48 }}
              ></ListItem>
              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={<Typography variant="caption">File URL:</Typography>}
                  secondary={<PaymentFileUrl />}
                ></ListItemText>
              </ListItem>
              <Divider />
              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">Is planned:</Typography>
                  }
                  secondary={
                    <BooleanField label="Is planned" source="isPlanned" />
                  }
                ></ListItemText>
              </ListItem>
              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">Is tax free:</Typography>
                  }
                  secondary={
                    <BooleanField label="Is tax free" source="isTaxFree" />
                  }
                ></ListItemText>
              </ListItem>
              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">Payment Info</Typography>
                  }
                  secondary={
                    <ReferenceField
                      link="show"
                      label="Payment Information"
                      source="paymentInformation.id"
                      reference="PaymentInformation"
                    >
                      <TextField source={PAYMENTINFORMATION_TITLE_FIELD} />
                    </ReferenceField>
                  }
                ></ListItemText>
              </ListItem>
              <Divider />
            </List>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};
