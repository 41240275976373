import * as React from "react";

import {
  BooleanField,
  Datagrid,
  DateField,
  DateTimeInput,
  List,
  ListProps,
  NullableBooleanInput,
  ReferenceField,
  TextField,
} from "react-admin";

import qs from "qs";
import { useLocation } from "react-router-dom";
import Pagination from "../Components/Pagination";
import { CLAIM_TITLE_FIELD } from "../claim/ClaimTitle";

export const PaymentPlanList = (props: ListProps): React.ReactElement => {
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  let jsonFilter: any = {
    isPlanned: "",
  };

  if (parsedLocation.filter) {
    try {
      jsonFilter = JSON.parse(parsedLocation.filter as string);
    } catch (e) {}
  }

  const [hasCourtTitleNoteValue, setHasCourtTitleNoteValue] = React.useState(
    jsonFilter.isPlanned?.toString()
  );
  const [latestDateFilterValue, setLatestDateFilterValue] = React.useState(
    jsonFilter.nextDueDate?.toString()
  );

  const paymentPlanFilters = [
    <NullableBooleanInput
      alwaysOn
      style={{ width: 200 }}
      source="hasCourtTitleNote"
      label="Has Court Title Note"
      value={hasCourtTitleNoteValue}
      onChange={(event) => {
        setHasCourtTitleNoteValue(event?.target?.value);
      }}
    />,
    <DateTimeInput
      value={latestDateFilterValue}
      onChange={(event) => {
        setLatestDateFilterValue(event?.target?.value);
      }}
      alwaysOn
      source="nextPaymentDueDate"
      label="Next Payment Due Date"
    />,
  ];

  return (
    <List
      {...props}
      hasCreate={false}
      exporter={false}
      bulkActionButtons={false}
      title={"PaymentPlans"}
      filter={{
        ...(hasCourtTitleNoteValue !== ""
          ? {
              ...{
                hasCourtTitleNote: {
                  equals: hasCourtTitleNoteValue === "true",
                },
              },
            }
          : {
              ...{
                hasCourtTitleNote: { not: undefined },
              },
            }),
        ...(latestDateFilterValue !== ""
          ? {
              ...{
                nextPaymentDueDate: { lte: latestDateFilterValue },
              },
            }
          : {
              ...{
                nextPaymentDueDate: { not: undefined },
              },
            }),
      }}
      filters={paymentPlanFilters}
      filterDefaultValues={{
        hasCourtTitleNote: null,
      }}
      perPage={50}
      empty={false}
      sort={{ field: "nextPaymentDueDate", order: "DESC" }}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="show" optimized style={{ marginTop: "1rem" }}>
        <ReferenceField
          label="Claim"
          source="claim.id"
          reference="Claim"
          link="show"
        >
          <TextField source={CLAIM_TITLE_FIELD} />
        </ReferenceField>
        <DateField
          source="createdAt"
          label="Created At"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
        <BooleanField label="Active" source="isActive" />
        <TextField label="Frequency" source="frequency" />
        <BooleanField label="Has Court Title Note" source="hasCourtTitleNote" />
        <TextField label="ID" source="id" />
        <TextField label="Next Payment Due Date" source="nextPaymentDueDate" />
        <TextField label="Number Of Payments" source="numberOfPayments" />
        <DateField
          source="updatedAt"
          label="Updated At"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
      </Datagrid>
    </List>
  );
};
