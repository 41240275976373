import { Grid, Tooltip } from "@material-ui/core";
import { AttachMoney, AvTimer, SortByAlphaOutlined } from "@material-ui/icons";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import React, { useEffect, useState } from "react";
import {
  AutocompleteInput,
  Button,
  ReferenceInput,
  SimpleForm,
} from "react-admin";
import ReactCountryFlag from "react-country-flag";
import { Claim } from "../../../api/claim/Claim";
import { theme } from "../../../theme/theme";
import { TODAY } from "../../../util/DateUtils";
import { ClaimSummary } from "../../ClaimSummary";
import GridHeader from "../../GridHeader";

const PlannedCallsToDoTab = React.memo((props: any) => {
  const limitPerPage = 25;
  const [claims, setClaims] = useState<Claim[]>([]);
  const [claimsToShow, setClaimsToShow] = useState(25); // local pagination
  const [languageFilter, setLanguageFilter] = useState<String | undefined>();
  const [userTypeFilter, setUserTypeFilter] = useState<String | undefined>();
  const [claimsSortOrder, setClaimsSortOrder] = useState("asc");
  const [claimsSortField, setClaimsSortField] = useState("updatedAt");
  const [activeSearchTerm, setActiveSearchTerm] = useState("");

  function toggleOpenClaimsSortField(field: string): void {
    setClaimsSortField(claimsSortField === "updatedAt" ? field : "updatedAt");
    if (claimsSortOrder === "asc" && claimsSortField === "updatedAt") {
      setClaimsSortOrder("desc");
    }
  }

  function toggleOpenClaimsSortOrder(): void {
    setClaimsSortOrder(claimsSortOrder === "desc" ? "asc" : "desc");
  }

  const fetchData = () => {
    const authHeader = {
      headers: {
        Authorization: localStorage.getItem("credentials") || "",
        "Content-Type": "application/json",
      },
    };

    props.setIsLoading(true);
    fetch(process.env.REACT_APP_SERVER_URL + "/api/claims/internal", {
      method: "POST",
      body: JSON.stringify({
        type: "calls",
        disablePersonalization:
          props.personalization === false ? true : undefined,
        enableLegal: props.enableLegal === true ? true : undefined,
        where: {
          creditorFeedbackRequired: {
            not: true,
          },
          stage: {
            in: ["Reminder", "Precourt", "Court", "Monitoring"],
          },
          status: {
            not: "Open",
          },
          activities: {
            some: {
              activityDate: {
                lte: TODAY,
              },
              activityType: "OutboundCall",
              isPlanned: true,
            },
          },
          ...(languageFilter === "German" && {
            debtor: {
              address: {
                country: {
                  in: ["DEU", "AUT", "CHE"],
                },
              },
              ...(userTypeFilter === "Consumer" && {
                businessType: "Consumer",
              }),
              ...(userTypeFilter === "Business" && {
                businessType: {
                  not: "Consumer",
                },
              }),
            },
          }),
          ...(languageFilter === "Local" && {
            debtor: {
              address: {
                country: {
                  in: [
                    "ALB",
                    "ARE",
                    "ARM",
                    "AZE",
                    "BEL",
                    "BIH",
                    "BGR",
                    "BHR",
                    "BLR",
                    "CYP",
                    "CZE",
                    "DNK",
                    "ESP",
                    "EST",
                    "FIN",
                    "FRA",
                    "GBR",
                    "GEO",
                    "GRC",
                    "HRV",
                    "HUN",
                    "IRL",
                    "ISL",
                    "ITA",
                    "KAZ",
                    "KGZ",
                    "LTU",
                    "LUX",
                    "LVA",
                    "MKD",
                    "MLT",
                    "MNE",
                    "MNG",
                    "NLD",
                    "NOR",
                    "POL",
                    "PRT",
                    "ROU",
                    "RUS",
                    "SAU",
                    "SRB",
                    "SVK",
                    "SVN",
                    "SWE",
                    "TJK",
                    "TKM",
                    "UKR",
                    "UZB",
                    "BMU",
                    "JEY",
                    // africa
                    "DZA",
                    "AGO",
                    "BEN",
                    "BFA",
                    "BDI",
                    "CPV",
                    "CMR",
                    "CAF",
                    "COM",
                    "COD",
                    "DJI",
                    "EGY",
                    "GNQ",
                    "ERI",
                    "SWZ",
                    "ETH",
                    "GAB",
                    "GMB",
                    "GHA",
                    "GIN",
                    "CIV",
                    "KEN",
                    "LSO",
                    "LBR",
                    "MDG",
                    "MWI",
                    "MLI",
                    "MAR",
                    "MOZ",
                    "NAM",
                    "NER",
                    "NGA",
                    "RWA",
                    "STP",
                    "SEN",
                    "SYC",
                    "SLE",
                    "SOM",
                    "ZAF",
                    "SSD",
                    "SDN",
                    "TZA",
                    "TGO",
                    "TUN",
                    "UGA",
                    "ZMB",
                    "ZWE",
                  ],
                },
              },
              ...(userTypeFilter === "Consumer" && {
                businessType: "Consumer",
              }),
              ...(userTypeFilter === "Business" && {
                businessType: {
                  not: "Consumer",
                },
              }),
            },
          }),
          ...(languageFilter === "NonLocal" && {
            debtor: {
              address: {
                country: {
                  notIn: [
                    "ALB",
                    "ARM",
                    "AZE",
                    "AUT",
                    "BEL",
                    "BIH",
                    "BGR",
                    "BLR",
                    "CHE",
                    "CYP",
                    "CZE",
                    "DEU",
                    "DNK",
                    "ESP",
                    "EST",
                    "FIN",
                    "FRA",
                    "GBR",
                    "GEO",
                    "GRC",
                    "HRV",
                    "HUN",
                    "IRL",
                    "ISL",
                    "ITA",
                    "KAZ",
                    "KGZ",
                    "LTU",
                    "LUX",
                    "LVA",
                    "MKD",
                    "MLT",
                    "MNE",
                    "NLD",
                    "NOR",
                    "POL",
                    "PRT",
                    "ROU",
                    "RUS",
                    "SRB",
                    "SVK",
                    "SVN",
                    "SWE",
                    "TJK",
                    "TKM",
                    "UKR",
                    "UZB",
                    // africa
                    "DZA",
                    "AGO",
                    "BEN",
                    "BFA",
                    "BDI",
                    "CPV",
                    "CMR",
                    "CAF",
                    "COM",
                    "COD",
                    "DJI",
                    "EGY",
                    "GNQ",
                    "ERI",
                    "SWZ",
                    "ETH",
                    "GAB",
                    "GMB",
                    "GHA",
                    "GIN",
                    "CIV",
                    "KEN",
                    "LSO",
                    "LBR",
                    "MDG",
                    "MWI",
                    "MLI",
                    "MAR",
                    "MOZ",
                    "NAM",
                    "NER",
                    "NGA",
                    "RWA",
                    "STP",
                    "SEN",
                    "SYC",
                    "SLE",
                    "SOM",
                    "ZAF",
                    "SSD",
                    "SDN",
                    "TZA",
                    "TGO",
                    "TUN",
                    "UGA",
                    "ZMB",
                    "ZWE",
                  ],
                },
              },
              ...(userTypeFilter === "Consumer" && {
                businessType: "Consumer",
              }),
              ...(userTypeFilter === "Business" && {
                businessType: {
                  not: "Consumer",
                },
              }),
            },
          }),
          ...(languageFilter === "NonGerman" && {
            debtor: {
              address: {
                country: {
                  notIn: ["DEU", "AUT", "CHE"],
                },
              },
              ...(userTypeFilter === "Consumer" && {
                businessType: "Consumer",
              }),
              ...(userTypeFilter === "Business" && {
                businessType: {
                  not: "Consumer",
                },
              }),
            },
          }),
          ...(userTypeFilter === "Consumer" && {
            debtor: {
              businessType: "Consumer",
            },
          }),
          ...(userTypeFilter === "Business" && {
            debtor: {
              businessType: {
                not: "Consumer",
              },
            },
          }),
          ...(activeSearchTerm && {
            creditor: {
              id: activeSearchTerm,
            },
          }),
        },
        orderBy: {
          [claimsSortField]: claimsSortOrder,
        },
      }),
      ...authHeader,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          props.setTotalPlannedCalls(responseJson.total);

          if (claimsSortField === "updatedAt") {
            const sortedClaims = responseJson.results?.sort(
              (a: Claim, b: Claim) =>
                new Date(
                  a.activities?.[0]?.activityDate || Date.now()
                ).getTime() -
                new Date(
                  b.activities?.[0]?.activityDate || Date.now()
                ).getTime()
            );
            setClaims(sortedClaims);
          } else {
            setClaims(responseJson.results);
          }
          props.setCallsLoaded(true);
          props.setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    if (props.tabValue === 0) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    claimsSortField,
    languageFilter,
    userTypeFilter,
    claimsSortOrder,
    props.personalization,
    props.enableLegal,
    activeSearchTerm,
  ]);

  return (
    <div
      role="tabpanel"
      className="notranslate"
      hidden={props.tabValue !== 0}
      id={`vertical-tabpanel-${0}`}
    >
      <div className="tab-col">
        <div className="flex-row" id="filter-group">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <SimpleForm
                toolbar={false}
                style={{
                  maxWidth: 340,
                }}
              >
                <ReferenceInput
                  filterToQuery={(searchText) =>
                    !!searchText && searchText.trim().length > 2
                      ? {
                          businessName: { startsWith: searchText },
                          roles: { array_contains: "creditor" },
                        }
                      : { roles: { array_contains: "creditor" } }
                  }
                  isRequired
                  allowEmpty
                  emptyText="-"
                  source={"creditor.businessName"}
                  reference={"User"}
                  label={"Creditor"}
                  onChange={(value) => {
                    setActiveSearchTerm(value);
                  }}
                >
                  <AutocompleteInput
                    suggestionLimit={5}
                    shouldRenderSuggestions={(value) => {
                      return value && value.trim().length > 2;
                    }}
                    optionText={"businessName"}
                  />
                </ReferenceInput>
              </SimpleForm>
            </Grid>
            <Grid item xs={12} md={6} className="filter-container">
              <Tooltip title={"Refresh"}>
                <RotateLeftIcon
                  style={{
                    cursor: "pointer",
                    marginLeft: 10,
                    marginRight: 10,
                    height: 20,
                  }}
                  onClick={() => fetchData()}
                />
              </Tooltip>

              <Tooltip
                title={
                  claimsSortField === "updatedAt"
                    ? "Sort by creation date"
                    : "Sort by last update"
                }
              >
                <AvTimer
                  style={{
                    cursor: "pointer",
                    marginLeft: 10,
                    marginRight: 10,
                    height: 20,
                    color:
                      claimsSortField === "updatedAt"
                        ? theme.palette.grey[500]
                        : theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    toggleOpenClaimsSortField("createdAt");
                  }}
                >
                  {claimsSortField === "updatedAt"
                    ? "Sort by creation date"
                    : "Sort by last update"}
                </AvTimer>
              </Tooltip>

              <Tooltip
                title={
                  claimsSortField === "updatedAt"
                    ? "Sort by value"
                    : "Sort by date"
                }
              >
                <AttachMoney
                  style={{
                    cursor: "pointer",
                    marginLeft: 10,
                    marginRight: 10,
                    height: 20,
                    color:
                      claimsSortField !== "totalPending"
                        ? theme.palette.grey[500]
                        : theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    toggleOpenClaimsSortField("totalPending");
                  }}
                >
                  {claimsSortField === "updatedAt"
                    ? "Sort by value"
                    : "Sort by date"}
                </AttachMoney>
              </Tooltip>

              <Tooltip
                title={
                  claimsSortOrder === "desc"
                    ? "Sort ascending"
                    : "Sort descending"
                }
              >
                <SortByAlphaOutlined
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    marginLeft: 10,
                    height: 20,
                    color:
                      claimsSortOrder === "asc"
                        ? theme.palette.grey[500]
                        : theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    toggleOpenClaimsSortOrder();
                  }}
                >
                  {claimsSortOrder === "desc"
                    ? "Sort ascending"
                    : "Sort descending"}
                </SortByAlphaOutlined>
              </Tooltip>
              <Tooltip
                title={"Filter B2X"}
                onClick={() => {
                  setUserTypeFilter(
                    userTypeFilter === "Business"
                      ? ""
                      : userTypeFilter === "Consumer"
                      ? "Business"
                      : "Consumer"
                  );
                }}
              >
                <span
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    marginLeft: 10,
                    color:
                      userTypeFilter === "Consumer"
                        ? "#995200"
                        : userTypeFilter === "Business"
                        ? "#000099"
                        : "",
                    background:
                      userTypeFilter === "Consumer"
                        ? "#ffc480"
                        : userTypeFilter === "Business"
                        ? "#d1d1ff"
                        : "",
                    padding: 5,
                    fontSize: 12,
                    fontWeight: "bold",
                    borderRadius: 25,
                  }}
                >
                  {userTypeFilter === "Consumer"
                    ? "B2C"
                    : userTypeFilter === "Business"
                    ? "B2B"
                    : "B2X"}
                </span>
              </Tooltip>
              <div
                onClick={() => {
                  if (!props.isLoading) {
                    setLanguageFilter(
                      languageFilter === undefined
                        ? "German"
                        : languageFilter === "German"
                        ? "Local"
                        : languageFilter === "Local"
                        ? "NonGerman"
                        : languageFilter === "NonGerman"
                        ? "NonLocal"
                        : undefined
                    );
                  }
                }}
              >
                <ReactCountryFlag
                  countryCode={languageFilter?.includes("Local") ? "eu" : "de"}
                  svg
                  style={{
                    cursor: "pointer",
                    padding: 5,
                    marginRight: 10,
                    marginLeft: 10,
                    borderRadius: 25,
                    fontSize: 22,
                    backgroundColor: languageFilter?.includes("Non")
                      ? theme.palette.error.light
                      : languageFilter === undefined
                      ? theme.palette.grey[400]
                      : theme.palette.success.light,
                    opacity: languageFilter === undefined ? 0.75 : 1,
                  }}
                />
                <span
                  style={{
                    cursor: "pointer",
                    color: languageFilter?.includes("Non")
                      ? theme.palette.error.light
                      : theme.palette.success.light,
                    fontSize: 12,
                    fontWeight: "bold",
                    borderRadius: 25,
                  }}
                >
                  {languageFilter &&
                    (languageFilter?.includes("Local") ? "EU+AFR" : "DACH")}
                </span>
              </div>
            </Grid>
          </Grid>
        </div>

        <GridHeader />

        {claims && claims.length > 0
          ? claims.slice(0, claimsToShow).map((claim: Claim, index: number) => {
              return (
                <ClaimSummary
                  claim={claim}
                  type="call"
                  index={index}
                  key={claim.id}
                  refresh={() => {
                    fetchData();
                  }}
                />
              );
            })
          : "-"}

        <Button
          style={{
            marginTop: 10,
            maxWidth: 200,
            marginLeft: 0,
          }}
          onClick={() => setClaimsToShow(claimsToShow + limitPerPage)}
          label="Load more"
        />
      </div>
    </div>
  );
});

export default PlannedCallsToDoTab;
