import { Assignment } from "@material-ui/icons";
import {
  Button,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  useDataProvider,
  useNotify,
} from "react-admin";
import { useFormState } from "react-final-form";

const AssignClaimForm = ({ claimId, setShow, refresh }) => {
  return (
    <SimpleForm toolbar={false}>
      <AssigneePicker claimId={claimId} setShow={setShow} refresh={refresh} />
    </SimpleForm>
  );
};

const AssigneePicker = (props: {
  claimId: string;
  setShow: any;
  refresh(): void;
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { values } = useFormState();

  const save = async () => {
    try {
      const updateClaim = await dataProvider.update("Claim", {
        id: props.claimId,
        data: { assignee: values.assignee || null },
        previousData: { id: props.claimId },
      });
      if (updateClaim) {
        notify("User assigned successfully", { type: "success" });
        props.setShow(false);
        props.refresh();
      }
    } catch (error) {
      notify(`Error: ${error.message}`, { type: "warning" });
    }
  };

  return (
    <div
      style={{
        width: 500,
        flex: 1,
        alignItems: "flex-end",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <ReferenceInput
        filterToQuery={() => ({
          businessName: {
            equals: "Debtist GmbH",
          },
          roles: {
            array_contains: "manager",
          },
        })}
        allowEmpty
        emptyText="(none)"
        source="assignee.id"
        reference="User"
        label="Assign User"
      >
        <SelectInput fullWidth optionText={(record) => record?.contactName} />
      </ReferenceInput>
      <br />
      <Button
        variant="contained"
        size="medium"
        startIcon={<Assignment />}
        color={"secondary"}
        onClick={() => save()}
        label="Assign User"
      />
    </div>
  );
};

export default AssignClaimForm;
