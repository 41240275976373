import { colors, Grid } from "@material-ui/core";
import * as React from "react";
import {
  AutocompleteInput,
  DeleteWithConfirmButton,
  Edit,
  EditProps,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import { UserTitle } from "../user/UserTitle";

export const PaymentInformationEdit = (
  props: EditProps
): React.ReactElement => {
  return (
    <Edit {...props} mutationMode="pessimistic">
      <SimpleForm
        toolbar={
          <Toolbar className="elevated-card">
            <DeleteWithConfirmButton
              undoable={false}
              mutationMode="pessimistic"
              label="Delete"
              variant="outlined"
              style={{
                borderColor: colors.red[400],
                padding: "6px 16px",
              }}
            />
            <SaveButton label="Save" />
          </Toolbar>
        }
      >
        <Grid container spacing={2} style={{ width: "100%" }}>
          <Grid item xs={12} lg={4}>
            <TextInput
              label="Account"
              source="account"
              parse={(value: string) =>
                value
                  .toUpperCase()
                  .replace(/[^\dA-Z]/g, "")
                  .replace(/(.{4})/g, "$1 ")
                  .trim()
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextInput
              label="Bank Identifier/SWIFT (only for international)"
              source="bankIdentifier"
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <SelectInput
              source="method"
              label="Method"
              choices={[
                { label: "DebitAccount", value: "DebitAccount" },
                { label: "PayPal", value: "PayPal" },
              ]}
              optionText="label"
              optionValue="value"
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <ReferenceInput
              filterToQuery={(searchText) => ({
                name: { startsWith: searchText },
              })}
              disabled
              source="user.id"
              reference="User"
              label="User"
            >
              <AutocompleteInput suggestionLimit={5} optionText={UserTitle} />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} lg={4}>
            <SelectInput
              source="currency"
              label="Currency (optional)"
              choices={[
                { label: "EUR", value: "EUR" },
                { label: "USD", value: "USD" },
                { label: "GBP", value: "GBP" },
                { label: "CHF", value: "CHF" },
                { label: "PLN", value: "PLN" },
                { label: "DKK", value: "DKK" },
                { label: "NOK", value: "NOK" },
                { label: "SEK", value: "SEK" },
              ]}
              optionText="label"
              allowEmpty
              optionValue="value"
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};
