import { createElement, useEffect } from "react";
import { MenuItemLink, getResources, useGetIdentity } from "react-admin";
import { withRouter } from "react-router-dom";
import { Logo } from "./Components/Logo";
import {
  FINANCE_MANAGERS,
  OPS_MANAGERS,
  TOOL_MANAGERS,
} from "./config/AppConfig";

import {
  BarChartRounded,
  HomeRounded,
  ShowChartRounded,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
const Menu = ({ onMenuClick }: any): JSX.Element => {
  let resources = useSelector(getResources);
  const { identity } = useGetIdentity();
  const dispatch = useDispatch();

  if (!TOOL_MANAGERS.includes(identity?.username.toString())) {
    resources = resources.filter((resource) =>
      ["Claim", "User", "Activity", "PaymentPlan", "Address"].includes(
        resource.name
      )
    );
  }

  useEffect(() => {
    // dispatch(setSidebarVisibility(false)); // set sidebar visibility to false on load
  }, [dispatch]);

  return (
    <div style={{ marginTop: "1rem", width: 240 }}>
      <Logo />
      <MenuItemLink
        to="/Home"
        primaryText="Home"
        activeStyle={{ background: "#5f84e1" }}
        style={{ fontWeight: 600 }}
        leftIcon={<HomeRounded />}
        onClick={onMenuClick}
      />
      {resources.map((resource) => (
        <MenuItemLink
          key={resource.name}
          activeStyle={{ background: "#5f84e1" }}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) || resource.name
          }
          style={
            resource.options.label?.startsWith("-") ? {} : { fontWeight: 600 }
          }
          leftIcon={createElement(resource.icon)}
          onClick={onMenuClick}
        />
      ))}
      {FINANCE_MANAGERS.includes(identity?.username.toString()) && (
        <MenuItemLink
          to="/PaymentOverview"
          primaryText="Payment Overview"
          activeStyle={{ background: "#5f84e1" }}
          style={{ fontWeight: 600 }}
          leftIcon={<ShowChartRounded />}
          onClick={onMenuClick}
        />
      )}
      {OPS_MANAGERS.includes(identity?.username.toString()) && (
        <MenuItemLink
          to="/OperationsOverview"
          primaryText="Operation Overview"
          activeStyle={{ background: "#5f84e1" }}
          style={{ fontWeight: 600 }}
          leftIcon={<BarChartRounded />}
          onClick={onMenuClick}
        />
      )}
    </div>
  );
};

export default withRouter(Menu);
