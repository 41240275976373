export enum EnumPaymentPaymentType {
  DunningCostExpense = "DunningCostExpense",
  DunningCostFee = "DunningCostFee",
  DefaultInterest = "DefaultInterest",
  ExistingCreditorExtras = "ExistingCreditorExtras",
  WriteOffDiscount = "WriteOffDiscount",
  DebtClearance = "DebtClearance",
  PaymentRate = "PaymentRate",
  Payout = "Payout",
  ExistingPayments = "ExistingPayments",
  CreditorExpenses = "CreditorExpenses",
  ClaimPosition = "ClaimPosition",
  CreditorInterest = "CreditorInterest",
  DebtCollectorTax = "DebtCollectorTax",
  DebtCollectorFee = "DebtCollectorFee",
  DebtCollectorOutboundPosition = "DebtCollectorOutboundPosition",
  DebtCollectorInboundPosition = "DebtCollectorInboundPosition",
}

export const NEGATIVE_PAYMENT_TYPES = [
  EnumPaymentPaymentType.WriteOffDiscount,
  EnumPaymentPaymentType.DebtClearance,
  EnumPaymentPaymentType.PaymentRate,
  EnumPaymentPaymentType.ExistingPayments,
  EnumPaymentPaymentType.DebtCollectorInboundPosition,
];

export const THIRD_PARTY_PAYMENT_TYPES = [
  EnumPaymentPaymentType.DebtCollectorOutboundPosition,
  EnumPaymentPaymentType.DebtCollectorInboundPosition,
];
