export enum EnumActivityResult {
  NotReached = "NotReached",
  ReachedPromisedToPay = "ReachedPromisedToPay",
  ReachedIgnored = "ReachedIgnored",
  ReachedRefusedToPay = "ReachedRefusedToPay",
  ReachedRefusedCorrectness = "ReachedRefusedCorrectness",
  ReachedPaymentPlanSetup = "ReachedPaymentPlanSetup",
  ReachedPaymentPlanAdjustment = "ReachedPaymentPlanAdjustment",
  ReachedPaymentPlanStop = "ReachedPaymentPlanStop",
  Reached = "Reached",
  Positive = "Positive",
  Negative = "Negative",
}
