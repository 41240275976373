export enum EnumUserBusinessContactRelationType {
  Shareholder = "Shareholder",
  ManagingDirector = "ManagingDirector",
  BoardMember = "BoardMember",
  AuthorizedOfficer = "AuthorizedOfficer",
  Owner = "Owner",
  SupervisoryBoardMember = "SupervisoryBoardMember",
  AuthorizedRepresentative = "AuthorizedRepresentative",
  OperationsManager = "OperationsManager",
  Founder = "Founder",
  Liquidator = "Liquidator",
}

export function getReadableBusinessContactRelationType(
  relationType: EnumUserBusinessContactRelationType | string
): string {
  switch (relationType) {
    case EnumUserBusinessContactRelationType.Shareholder:
      return "Gesellschafter";
    case EnumUserBusinessContactRelationType.ManagingDirector:
      return "Geschäftsführer";
    case EnumUserBusinessContactRelationType.BoardMember:
      return "Vorstandsmitglied";
    case EnumUserBusinessContactRelationType.AuthorizedOfficer:
      return "Prokurist";
    case EnumUserBusinessContactRelationType.Owner:
      return "Inhaber";
    case EnumUserBusinessContactRelationType.SupervisoryBoardMember:
      return "Aufsichtsratsmitglied";
    case EnumUserBusinessContactRelationType.AuthorizedRepresentative:
      return "Bevollmächtigter";
    case EnumUserBusinessContactRelationType.OperationsManager:
      return "Betriebsleiter";
    case EnumUserBusinessContactRelationType.Founder:
      return "Gründer";
    case EnumUserBusinessContactRelationType.Liquidator:
      return "Liquidator";
    default:
      return "";
  }
}
