/**
 * Searches a Map by value and returns the corresponding key.
 *
 * @param {Map} map - The Map object to search.
 * @param {any} value - The value to search for.
 * @return {any} The key associated with the value, or undefined if not found.
 */
export function searchMapByKey(map: Map<any, any>, value: any): any {
  const entries = Array.from(map.entries());
  for (const [key, val] of entries) {
    if (key === value) {
      return val;
    }
  }

  return undefined;
}
