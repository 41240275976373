import MuiAvatar from "@material-ui/core/Avatar";
import { TimerTwoTone } from "@material-ui/icons";
import { FunctionField, ReferenceField, useRecordContext } from "react-admin";
import { theme } from "../theme/theme";

const UserPresentation = () => {
  const activity = useRecordContext();

  if (!activity?.manager) {
    return (
      <div
        style={{
          minWidth: 120,
          marginRight: 15,
          marginLeft: -15,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textAlign: "center",
          textOverflow: "ellipsis",
        }}
      >
        {["Response", "CreditorFeedback", "CreditorDecision"].includes(
          activity?.activityType
        ) && (
          <MuiAvatar
            style={{
              width: 23,
              height: 23,
              margin: "0 auto",
              border: "2px solid",
              background:
                activity?.activityType === "Response"
                  ? theme.palette.error.dark
                  : activity?.activityType === "CreditorFeedback" ||
                    activity?.activityType === "CreditorDecision"
                  ? theme.palette.success.dark
                  : theme.palette.secondary.main,
              borderColor:
                activity?.activityType === "Response"
                  ? theme.palette.error.dark
                  : activity?.activityType === "CreditorFeedback" ||
                    activity?.activityType === "CreditorDecision"
                  ? theme.palette.success.dark
                  : theme.palette.secondary.main,
            }}
          />
        )}
        {!activity?.activityType?.startsWith("Recommended") &&
          activity?.isPlanned && (
            <div style={{ marginTop: 4 }}>
              <MuiAvatar
                style={{
                  width: 25,
                  height: 25,
                  margin: "0 auto",
                  background: theme.palette.primary.main,
                }}
              >
                <TimerTwoTone
                  fontSize={"small"}
                  style={{
                    width: 17,
                    height: 17,
                    color: "#fff",
                    position: "relative",
                  }}
                />
              </MuiAvatar>
              <span style={{ fontSize: 11, color: theme.palette.primary.main }}>
                {"Geplant"}
              </span>
            </div>
          )}
        <strong
          style={{
            fontSize: 11,
            color:
              activity?.activityType === "Response"
                ? theme.palette.error.dark
                : activity?.activityType === "CreditorFeedback" ||
                  activity?.activityType === "CreditorDecision"
                ? theme.palette.success.dark
                : theme.palette.secondary.main,
          }}
        >
          {activity?.activityType === "Response"
            ? "Debtor"
            : activity?.activityType === "CreditorFeedback" ||
              activity?.activityType === "CreditorDecision"
            ? "Creditor"
            : ""}
        </strong>
      </div>
    );
  }

  return (
    <div
      style={{
        minWidth: 120,
        marginRight: 15,
        marginLeft: -15,
        textAlign: "center",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <ReferenceField link="show" label="" source="manager.id" reference="User">
        <FunctionField
          label="Manager"
          render={(record: any) => {
            if (record) {
              return (
                <>
                  <MuiAvatar
                    style={{
                      width: 25,
                      height: 25,
                      margin: "0 auto",
                    }}
                    src={
                      "https://debtist2023.sharepoint.com/_layouts/15/userphoto.aspx?size=L&username=" +
                      record?.username
                    }
                  />
                  <strong style={{ fontSize: 11 }}>
                    {record?.username?.split("@")?.[0]}
                    {activity?.isPlanned && (
                      <div style={{ fontWeight: "normal" }}>Geplant</div>
                    )}
                  </strong>
                </>
              );
            }
          }}
        />
      </ReferenceField>
    </div>
  );
};

export default UserPresentation;
