import { colors, Grid } from "@material-ui/core";
import * as React from "react";
import {
  AutocompleteInput,
  DeleteWithConfirmButton,
  Edit,
  EditProps,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import { useFormState } from "react-final-form";
import { UserTitle } from "../user/UserTitle";
import {
  ADDRESS_CHOICES,
  AddressLine1Input,
  AddressLine2Input,
} from "./AddressCreate";

export const AddressEdit = (props: EditProps): React.ReactElement => {
  const handleKeyPress = React.useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/Address/" + props.id + "/show", "_self");
      }
    },
    [props.id]
  );

  React.useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Edit {...props} mutationMode="pessimistic">
      <SimpleForm
        toolbar={
          <Toolbar className="elevated-card">
            <DeleteWithConfirmButton
              undoable={false}
              mutationMode="pessimistic"
              label="Delete"
              variant="outlined"
              style={{
                borderColor: colors.red[400],
                padding: "6px 16px",
              }}
            />
            <SaveButton label="Save" />
          </Toolbar>
        }
      >
        <Grid container spacing={2} style={{ width: "100%" }}>
          <Grid item xs={12} md={6}>
            <ReferenceInput
              filterToQuery={(searchText) => ({
                name: { startsWith: searchText },
              })}
              source="user.id"
              reference="User"
              label="User"
            >
              <AutocompleteInput suggestionLimit={5} optionText={UserTitle} />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6} className="mobile-hide"></Grid>
          <Grid item xs={12} md={6}>
            <AddressLine1Input />
          </Grid>
          <Grid item xs={12} md={6}>
            <AddressLine2Input />
          </Grid>

          <Grid item xs={12} md={6}>
            <PostalCodeAndTownInput />
          </Grid>

          <Grid item xs={12} md={6}>
            <SelectInput
              source="country"
              label="Country"
              choices={ADDRESS_CHOICES}
              optionText="label"
              optionValue="value"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              source="verificationStatus"
              label="Verification Status"
              choices={[
                { label: "VALID", value: "VALID" },
                { label: "INVALID", value: "INVALID" },
                { label: "UNKNOWN", value: "UNKNOWN" },
              ]}
              optionText="label"
              allowEmpty
              optionValue="value"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LanguageInput />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const LanguageInput = () => {
  return (
    <SelectInput
      source="languageCountry"
      label="Language"
      choices={[
        {
          label: "Deutsch",
          value: "DEU",
        },
        {
          label: "Français",
          value: "FRA",
        },
        {
          label: "English",
          value: "GBR",
        },
        {
          label: "Italian",
          value: "ITA",
        },
      ]}
      optionText="label"
      optionValue="value"
      allowEmpty
    />
  );
};

const TownInput = () => {
  const { values } = useFormState();

  return (
    <TextInput
      required
      style={{ width: "55%" }}
      label="Town"
      source="town"
      autoComplete="off"
      onBlur={(e) => {
        values.town = e.target.value.trim();
      }}
    />
  );
};

const PostalCodeAndTownInput = () => {
  const { values } = useFormState();

  return (
    <div>
      <TextInput
        required
        style={{ width: "40%", marginRight: "5%" }}
        label="Postalcode"
        source="postalcode"
        autoComplete="off"
        onBlur={(e) => {
          values.postalcode = e.target.value.trim();
        }}
      />

      <TownInput />
    </div>
  );
};
